import { magic } from "./magic";
import { getProvider } from "./provider";

// When a user logs out, disconnect with Magic & re-set web3 provider
// https://magic.link/docs/connect/wallet-api-reference/javascript-client-sdk#getprovider()

interface LogoutProps {
  setWeb3: React.Dispatch<React.SetStateAction<any>>;
  setUser: React.Dispatch<React.SetStateAction<string | null>>;
  removeCookie: (name: "user" | "signature", options?: any) => void;
}

export const logout = async ({
  setWeb3,
  setUser,
  removeCookie,
}: LogoutProps) => {
  await magic.wallet.disconnect(); // As of version 17 this doesn't clear properly (user needs to refresh or if they prev signed in w/ metamask it will reconnect w/ metamask )
  const provider = await getProvider();
  setWeb3(provider);
  setUser(null);

  // Remove cookies
  removeCookie("user");
  removeCookie("signature");

  console.log("Successfully disconnected");
};
