import { useState } from "react";
import { useUptop } from "../../contexts/UptopContext";
import Tippy, { useSingleton } from "@tippyjs/react";
import Help from "@mui/icons-material/HelpOutlineTwoTone";
import SecondaryButton from "../Buttons/SecondaryButton";
import { Tab } from "@headlessui/react";
import { classNames } from "../../lib/util";

const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

type Props = {
  showTooltip?: boolean;
};
export default function NotificationSettingsForm({ showTooltip }: Props) {
  const { email, UpdateEmail } = useUptop();
  const [emailInput, setEmailInput] = useState("");
  const [emailValid, setEmailValid] = useState(true);
  const [loading, setLoading] = useState(false);
  const [responseMessage, setResponseMessage] = useState("");

  const { notificationStatus } = useUptop();
  const defaultNotificationStatus = !email ? true : notificationStatus;
  const [notificationsEnabled, setNotificationsEnabled] = useState(
    defaultNotificationStatus,
  );

  const notificationTabs = [
    { value: true, label: "ON" },
    { value: false, label: "OFF" },
  ];

  const [source, target] = useSingleton({
    overrides: ["placement"],
  });

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setResponseMessage("");
    setLoading(true);
    const response = await UpdateEmail(emailInput, notificationsEnabled);
    if (response !== "Success") {
      setEmailValid(false);
    } else {
      setEmailInput("");
    }
    setResponseMessage(response);
    setLoading(false);
  };

  const handleEmailInput = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (responseMessage) {
      setResponseMessage("");
    }
    const isEmailValid = emailRegex.test(event.target.value);
    setEmailValid(isEmailValid);
    setEmailInput(event.target.value.trim());
  };

  return (
    <>
      <form
        onSubmit={handleSubmit}
        className="flex flex-col w-full gap-2"
      >
        <Tippy
          singleton={source}
          delay={0}
          className="shadow-uiBoxShadowCard bg-uptop-tan mx-4 p-4  rounded-lg border-2 border-black"
        />
        {email && (
          <>
            <Tab.Group>
              <Tab.List className="w-56 h-12 p-1 bg-white shadow-uiBoxShadowMisc rounded-lg border border-black flex justify-start items-start ">
                {notificationTabs.map((tab) => (
                  <Tab
                    key={tab.label}
                    onClick={() => setNotificationsEnabled(tab.value)}
                    className={({ selected }) =>
                      classNames(
                        "py-2.5 text-sm font-medium leading-tight tracking-wide flex-grow font-exposure",
                        selected
                          ? "px-4 bg-uptop-purple text-white rounded-lg"
                          : "h-10 px-4 text-zinc-500", // adjust the height here
                        "justify-center items-center flex",
                      )
                    }
                  >
                    {tab.label}
                  </Tab>
                ))}
              </Tab.List>
            </Tab.Group>
            <div className="flex gap-1 mt-1">
              <label className="font-exposure text-surface-700  text-sm flex  gap-2 mb-auto">
                Current Email:
              </label>
              <div className="font-exposure my-auto text-sm  w-48  overflow-hidden break-all">
                {email}
              </div>
            </div>
          </>
        )}
        <div className="flex gap-1">
          <label
            htmlFor="email"
            className="font-exposure my-auto text-sm flex  gap-2 text-surface-700"
          >
            Email
          </label>
          {showTooltip && (
            <Tippy
              content={
                "Where can we notify you when your rewards are updated and new offers are available?"
              }
              singleton={target}
              placement="bottom-end"
            >
              <Help className="h-3 w-3 text-surface-500  my-auto active:text-black " />
            </Tippy>
          )}
          <label
            className={`${
              responseMessage === "Success"
                ? "text-green-500"
                : "text-uptop-red"
            } font-exposure  text-sm flex  ml-2 my-auto`}
          >
            {responseMessage}
          </label>
        </div>
        <div className="flex gap-2">
          <input
            id="email"
            data-testid="email"
            type="text"
            name="addresses"
            onChange={handleEmailInput}
            value={emailInput}
            placeholder=""
            className={`border-2 ${
              emailValid
                ? "border-transparent active:border-black"
                : "border-uptop-red active:border-uptop-red"
            } border-solid font-exposureItalic rounded-full p-4 w-full  bg-surface-200 disabled:bg-surface-600 disabled:text-transparent  pl-4 min-w-[200px] text-xs  tracking-wider`}
          />

          <SecondaryButton
            type="submit"
            className="disabled:cursor-auto font-exposureItalic font-thin tracking-wide mx-auto text-sm  min-w-[7rem] justify-center items-center"
            disabled={
              loading ||
              ((email === emailInput || !emailValid || !emailInput) &&
                defaultNotificationStatus === notificationsEnabled)
            }
          >
            {loading ? "Saving..." : "Save"}
          </SecondaryButton>
        </div>
      </form>
    </>
  );
}
