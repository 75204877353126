import { Dialog, Transition } from "@headlessui/react";
import CloseOutlined from "@mui/icons-material/CloseOutlined";
import { Fragment } from "react";
import { AllCommissions, IPromo } from "../../types";
import { useUptop } from "../../contexts/UptopContext";
import Badge from "../ReusableUI/Badge";

type Props = {
  activePromo: IPromo | undefined;
  setActivePromo: React.Dispatch<React.SetStateAction<IPromo | undefined>>;
};
export default function CashbackOfferModal({
  activePromo,
  setActivePromo,
}: Props) {
  const { earnedRewards, activatedOffers, hasLinkedCard } = useUptop();

  if (!activePromo || activePromo.promoType !== "cashback") {
    return <></>;
  }

  const offerActive = activatedOffers.some(
    (promo) => promo.promoId === activePromo.promoId,
  );

  const rewardTotal = offerActive
    ? earnedRewards.reduce((acc: number, reward: AllCommissions) => {
        if (reward.CommissionID === activePromo.promoId)
          acc += reward.CashbackAmount;
        return acc;
      }, 0)
    : 0;

  const statusText =
    hasLinkedCard && offerActive
      ? "Active"
      : !hasLinkedCard
      ? "Link Required"
      : "Activation Required";

  return (
    <>
      <Transition
        appear
        show={!!activePromo}
        as={Fragment}
      >
        <Dialog
          as="div"
          className="relative z-10"
          onClose={() => setActivePromo(undefined)}
        >
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black bg-opacity-50" />
          </Transition.Child>

          <div className="fixed inset-0 top-16 md:top-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className=" border-2 border-black shadow-uiBoxShadowCard bg-uptop-tan flex flex-col gap-4 max-w-sm   w-full transform overflow-hidden rounded-lg  py-4 px-8 text-left  transition-all">
                  <div className="flex flex-row ">
                    <Dialog.Title
                      as="h2"
                      className="w-full  my-auto font-exposure text-xl text-center md:text-left "
                    >
                      {activePromo.merchantName} Offer Details
                    </Dialog.Title>
                    <button
                      onClick={() => setActivePromo(undefined)}
                      className="btn-reverse btn-white  group p-2 rounded-md  w-fit  my-auto"
                    >
                      <CloseOutlined />
                    </button>
                  </div>
                  <div className="flex flex-col gap-2 ">
                    <div className="flex flex-col gap-1">
                      <div className="text-surface-700 text-sm font-exposure">
                        Description
                      </div>

                      <div className="font-exposure">
                        Shop with supported partners to earn cash back in{" "}
                        {activePromo.rewardSymbol}
                      </div>
                    </div>
                    <div className="flex flex-col gap-1">
                      <div className="text-surface-700 text-sm font-exposure">
                        Cash Back Rate
                      </div>
                      <div className="font-exposure">
                        {parseFloat(activePromo.cashbackAmount ?? "0") * 100}%
                      </div>
                    </div>
                    <div className="flex flex-col gap-1">
                      <div className="text-surface-700 text-sm font-exposure">
                        {activePromo.rewardSymbol} Earned
                      </div>
                      <div className="font-exposure">
                        {rewardTotal.toFixed(4)}
                      </div>
                    </div>
                    <div className="flex flex-col gap-1">
                      <div className="text-surface-700 text-sm font-exposure">
                        Supported Partners
                      </div>
                      <ul className="list-inside list-disc">
                        {activePromo?.partnerMerchants.map(
                          (merchant: string) => {
                            return (
                              <li
                                className="font-exposure"
                                key={merchant}
                              >
                                {merchant}
                              </li>
                            );
                          },
                        )}
                      </ul>
                    </div>{" "}
                    <div className="flex flex-col gap-1">
                      <div className="text-surface-700 text-sm font-exposure">
                        Status
                      </div>
                      <div className="font-exposure">
                        <Badge
                          color={statusText === "Active" ? "green" : "red"}
                          text={statusText}
                        />
                      </div>
                    </div>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </>
  );
}
