import { ReactNode, createContext, useContext, useMemo, useState } from "react";
import { useMount } from "react-use";
import { getWeb3 } from "../lib/magic/web3";

interface Web3ContextType {
  web3: any;
  setWeb3: React.Dispatch<React.SetStateAction<any>>;
}
const Web3Context = createContext<Web3ContextType>({} as any);

export const Web3Provider = ({ children }: { children: ReactNode }) => {
  // Magic doesn't provide a type for web3.currentProvider, so unless we want errors we have to keep it as any
  const [web3, setWeb3] = useState<any>();

  const value: Web3ContextType = useMemo(
    () => ({
      web3,
      setWeb3,
    }),
    [web3, setWeb3],
  );

  useMount(() => {
    getWeb3().then(setWeb3);
  });

  return <Web3Context.Provider value={value}>{children}</Web3Context.Provider>;
};

export function useWeb3() {
  return useContext(Web3Context);
}
