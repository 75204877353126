import { BLOCK_EXPLORER_URL } from "../../config";
import { BLOCK_EXPLORER_URLS, NetworkName } from "../../constants";
import shortenHexAddress from "../../lib/util";
import Launch from "@mui/icons-material/Launch";
type ShortenedAddressProps = {
  address?: string;
  type?: "address" | "tx";
  displayValue?: string;
  length?: number;
  network?: string;
  textColor?: string;
};

export default function ShortenedAddress({
  address,
  type = "address",
  displayValue,
  length,
  network,
  textColor,
}: ShortenedAddressProps) {
  if (!address) {
    return <></>;
  }
  // Uses the default network based on NODE_ENV (mumbai/polygon) if one isn't provided
  const BLOCK_EXPLORER =
    network && network in BLOCK_EXPLORER_URLS
      ? BLOCK_EXPLORER_URLS[network as NetworkName]
      : BLOCK_EXPLORER_URL;

  return (
    <a
      className=" break-words hover:underline "
      href={BLOCK_EXPLORER + `/${type}/` + address}
      target="_blank"
      rel="noreferrer noopener"
    >
      <span
        className={`font-exposureItalic  ${
          textColor ? textColor : "text-black"
        }`}
      >
        {displayValue ? displayValue : shortenHexAddress(address, length)}
      </span>
      <Launch className="my-auto ml-1" />
    </a>
  );
}
