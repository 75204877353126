import { Fallback } from "./UptopLogoFallback";
import { useState } from "react";

type ImageProps = {
  className?: string;
  imageURL?: string;
  useFallback?: boolean;
  alt?: string;
};

export default function CustomImage({
  className,
  imageURL,
  useFallback = true,
  alt,
}: ImageProps) {
  const [hasError, setHasError] = useState(false);

  if ((hasError || !imageURL) && !useFallback) {
    return (
      <div
        className={`${className}  bg-uptop-redTint text-white  font-exposure  flex justify-center items-center`}
      >
        {alt}
      </div>
    );
  }

  return (
    <img
      className={className}
      src={formatURL(imageURL) || Fallback}
      onError={(e) => {
        if (useFallback) {
          e.currentTarget.src = Fallback;
        } else {
          setHasError(true);
        }
      }}
      alt={alt}
    />
  );
}

const formatURL = (imageURL: string | undefined): string => {
  let url = imageURL ?? "";
  return url.replace("ipfs://", "https://gateway.ipfs.io/ipfs/");
};
