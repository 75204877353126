import AccessTimeOutlined from "@mui/icons-material/AccessTimeOutlined";
import ContentCopyOutlined from "@mui/icons-material/ContentCopyOutlined";

import { decode } from "he";
import { useState } from "react";
import { toast } from "react-toastify";
import { useUptop } from "../../contexts/UptopContext";
import { sanitizeString } from "../../lib/util";
import { Coupons } from "../../types/wildfire";
import TokenGate from "../DealPass/TokenGate";
import Pagination from "../Pagination";

type CouponTableProps = { allCoupons: Coupons; searchTerm?: string };
export default function CouponTable({
  allCoupons,
  searchTerm,
}: CouponTableProps) {
  const { country, hasDealPass } = useUptop();
  const [currentPage, setCurrentPage] = useState(1);
  if (!allCoupons || !allCoupons.length) {
    return (
      <h1 className="font-exposureItalic text-2xl text-center mx-auto pb-8 pt-4 text-surface-700">
        No coupons available 😔
      </h1>
    );
  }
  // Pagination logic
  function handlePageChange(newPage: number) {
    setCurrentPage(newPage);
  }

  // Filter and sort the coupons first
  const filteredAndSortedCoupons = allCoupons
    .filter((coupon) =>
      coupon?.Countries ? coupon.Countries.includes(country) : true,
    )
    .filter((coupon) => {
      if (searchTerm) {
        const searchTermLower = sanitizeString(searchTerm);
        const offerNameMatches = sanitizeString(coupon.Description).includes(
          searchTermLower,
        );

        return offerNameMatches;
      }
      return true;
    })
    .sort((a, b) => {
      const daysRemainingA = calculateDaysRemaining(a.EndDate);
      const daysRemainingB = calculateDaysRemaining(b.EndDate);

      return daysRemainingA - daysRemainingB;
    });

  const itemsPerPage = 5;
  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;

  const coupons = filteredAndSortedCoupons.slice(startIndex, endIndex);

  const totalPages = Math.ceil(filteredAndSortedCoupons.length / itemsPerPage);

  return (
    <>
      <div className=" flex flex-col mx-auto text-center 2xl:mx-0 2xl:text-left 2xl:flex-row ">
        {totalPages > 1 && (
          <div className="w-fit ml-auto  2xl:absolute  right-8 top-0 ">
            <Pagination
              currentPage={currentPage}
              totalPages={totalPages}
              onPageChange={handlePageChange}
            />
          </div>
        )}
      </div>
      <div className="min-h-[400px] h-full ">
        {!coupons.length ? (
          <h1 className="font-exposureItalic text-2xl mt-8 text-center text-surface-700">
            No coupons found 😔
          </h1>
        ) : (
          <div className="flex flex-col overflow-x-auto overflow-y-auto ">
            {coupons.map((coupon, idx) => {
              const daysRemaining = calculateDaysRemaining(coupon.EndDate);
              const daysRemainingColor =
                daysRemaining < 5
                  ? "text-red-600"
                  : daysRemaining < 10
                  ? "text-yellow-600"
                  : "text-green-600";

              return (
                <div
                  key={coupon.ID}
                  className={` flex flex-col 2xl:grid 2xl:grid-cols-5 ${
                    (idx + 1) / itemsPerPage !== 1 &&
                    "border-b-2 border-surface-400"
                  }`}
                >
                  <div className="px-4 py-2 2xl:py-4 font-exposure text-lg  ">
                    {coupon.Code ? (
                      <TokenGate
                        customText="View code with Deal Pass"
                        unlockCondition={hasDealPass}
                      >
                        <button
                          className="flex flex-row gap-2 group"
                          onClick={async () => {
                            if (coupon.Code) {
                              await navigator.clipboard.writeText(coupon.Code);
                              toast.info(`Copied ${coupon.Code} to clipboard`);
                            }
                          }}
                        >
                          <h1 className="font-exposure text-xl group-hover:text-uptop-purple">
                            CODE: {coupon.Code}
                          </h1>
                          <ContentCopyOutlined className="h-5 w-5 group-hover:text-uptop-purple" />
                        </button>
                      </TokenGate>
                    ) : (
                      "N/A"
                    )}
                  </div>
                  <div className="px-4 py-2 2xl:py-4 font-exposure text-lg col-span-3">
                    {decode(coupon.Description)} {decode(coupon.Exclusions)}
                  </div>

                  <div className="px-4 py-2 2xl:py-4 font-exposure text-lg">
                    <h1
                      className={` ${daysRemainingColor} flex gap-1 mx-auto md:ml-auto md:mr-0 font-exposure  my-auto`}
                    >
                      <AccessTimeOutlined className="w-5 h-5 my-auto" />
                      {daysRemaining === 0
                        ? `Ends today`
                        : `Ends in ${daysRemaining} ${
                            daysRemaining === 1 ? "day" : "days"
                          }`}
                    </h1>
                  </div>
                </div>
              );
            })}
          </div>
        )}
      </div>
    </>
  );
}

function calculateDaysRemaining(endDateString: string) {
  const endDate = new Date(endDateString);
  const currentDate = new Date();
  const timeDifference = Number(endDate) - Number(currentDate);
  const daysRemaining = Math.ceil(timeDifference / (1000 * 60 * 60 * 24));
  return daysRemaining;
}
