import CloseOutlined from "@mui/icons-material/CloseOutlined";
import FaceOutlined from "@mui/icons-material/FaceOutlined";
import NotesOutlined from "@mui/icons-material/NotesOutlined";
import { Link, useLocation } from "react-router-dom";
import { useSidebarRouting } from "../../../../contexts/SidebarRoutingContext";

export default function NavbarMobileButtons() {
  const location = useLocation();
  const currentPath = location.pathname.split("/")[1];

  const { toggleMenu, showMenu } = useSidebarRouting();

  return (
    <div className="flex gap-2">
      <Link
        to={currentPath !== "YourAccount" ? "/YourAccount" : "/"}
        className={`${
          currentPath === "YourAccount" ? "bg-black" : "bg-surface-300"
        } rounded-[4px] p-2 btn `}
      >
        {currentPath === "YourAccount" ? (
          <FaceOutlined className="text-white " />
        ) : (
          <FaceOutlined />
        )}
      </Link>
      <button
        id="ShowMenu"
        onClick={toggleMenu}
        className={`${
          showMenu ? "bg-black" : "bg-surface-300"
        } rounded-[4px] p-2 btn `}
      >
        {showMenu ? (
          <CloseOutlined className="text-white " />
        ) : (
          <NotesOutlined />
        )}
      </button>
    </div>
  );
}
