type COLORS = "purple" | "red" | "orange" | "green";

type BadgeProps = { color: COLORS; text: string; width?: string };
export default function Badge({ color, text, width = "w-fit" }: BadgeProps) {
  const themes: Record<COLORS, string> = {
    green: "bg-uptop-green/25 text-uptop-green",
    purple: "bg-uptop-purple/25 text-uptop-purple",
    orange: "bg-uptop-orange/25 text-uptop-orange",
    red: "bg-uptop-red/10 text-uptop-redTint",
  };

  return (
    <div
      className={`px-6 py-2 ${width} rounded-full text-center text-sm md:text-base font-exposure ${themes[color]}`}
      title={text} // This creates a native tooltip on hover!
    >
      {text}
    </div>
  );
}
