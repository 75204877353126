import { Tab } from "@headlessui/react";
import { useState } from "react";
import { useUptop } from "../../contexts/UptopContext";
import { useCashbackSettings } from "../../hooks/useCashbackSettings";
import { classNames } from "../../lib/util";
import { IPayoutPreference } from "../../types";
import Loading from "../ReusableUI/Loading";

type Props = {
  showTooltip?: boolean;
};
export default function CashbackSettingsTabs({ showTooltip }: Props) {
  const { UpdatePayoutPreference } = useUptop();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const {
    cashbackSettings,
    selectedSetting,
    setSelectedSetting,
    error,
    cashbackSettingsLoading,
  } = useCashbackSettings();

  const submitForm = async () => {
    setIsSubmitting(true);
    await UpdatePayoutPreference(selectedSetting);
    setIsSubmitting(false);
  };

  const handleTabClick = async (cashbackSetting: IPayoutPreference) => {
    setSelectedSetting(cashbackSetting.payoutPreferenceId);
    await submitForm();
  };

  if (error || !Array.isArray(cashbackSettings)) {
    return (
      <h1 className="text-uptop-red font-exposure text-lg">
        Error fetching cashback settings 😔
      </h1>
    );
  }

  if (cashbackSettingsLoading) {
    return <Loading size={20} />;
  }

  return (
    <Tab.Group>
      <Tab.List className="w-fit h-14 p-1 bg-white shadow-uiBoxShadowMisc rounded-lg  border border-black flex justify-start items-start ">
        {cashbackSettings.map((cashbackSetting) => (
          <Tab
            key={cashbackSetting.payoutPreferenceId}
            onClick={() => handleTabClick(cashbackSetting)}
            disabled={isSubmitting}
            className={({ selected }) =>
              classNames(
                "py-3.5 text-sm font-medium leading-tight tracking-wide font-exposure",
                selected
                  ? "px-4 bg-uptop-purple text-white rounded-lg"
                  : "h-12 px-4 text-zinc-500",
                "justify-center items-center flex",
              )
            }
          >
            {cashbackSetting.symbol}
          </Tab>
        ))}
      </Tab.List>
    </Tab.Group>
  );
}
