import { useUser } from "../../contexts/UserContext";
import PrimaryButton from "./PrimaryButton";
import SecondaryButton from "./SecondaryButton";

type ConnectButtonProps = {
  buttonText?: "Login / Sign Up" | "Login To Earn" | "Login To See Rewards";
};
export default function ConnectButton({
  buttonText = "Login / Sign Up",
}: ConnectButtonProps) {
  const { handleConnect, disabled } = useUser();

  if (buttonText === "Login / Sign Up") {
    return (
      <SecondaryButton
        outlined={disabled}
        onClick={handleConnect}
        disabled={disabled}
        className="w-fit mx-auto"
      >
        <span className="my-auto font-exposureItalic font-thin ">
          {disabled ? "Connecting..." : buttonText}
        </span>
      </SecondaryButton>
    );
  }
  return (
    <PrimaryButton
      onClick={handleConnect}
      className="rounded-full max-h-12 m-auto   "
      disabled={disabled}
    >
      <span className="my-auto font-exposureItalic font-thin  ">
        {disabled ? "Connecting..." : buttonText}
      </span>
    </PrimaryButton>
  );
}
