type BuildWildfireOfferLinktype = {
  wildfireMerchantId: string | number;
  deviceID: number;
  trackingCode?: string;
  url?: string;
  preferredCountry?: string;
};
export function buildWildfireOfferLink({
  wildfireMerchantId,
  deviceID,
  trackingCode,
  url,
  preferredCountry,
}: BuildWildfireOfferLinktype) {
  if (!wildfireMerchantId || !deviceID) {
    return "";
  }
  const tracking = trackingCode ? `&tc=${trackingCode}` : "";
  const encodedURL = url ? `&url=${encodeURI(url)}` : "";
  const preferred = preferredCountry ? `&pc=${preferredCountry}` : "";
  return `https://wild.link/e?c=${wildfireMerchantId}&d=${deviceID}${tracking}${preferred}${encodedURL}`;
}
