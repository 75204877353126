import { Dialog, Transition } from "@headlessui/react";
import { Fragment } from "react";
import { useUptop } from "../contexts/UptopContext";

export default function ForbiddenModal() {
  const { forbiddenMessage, forbiddenRedirect } = useUptop();

  return (
    <>
      <Transition
        appear
        show={!!forbiddenMessage}
        as={Fragment}
      >
        <Dialog
          as="div"
          className="relative z-10"
          onClose={() => {}}
        >
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-white bg-opacity-100" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="w-full max-w-2xl transform overflow-hidden rounded-lg bg-uptop-tan border-2 border-black shadow-uiBoxShadow p-6 text-left align-middle  transition-all">
                  <Dialog.Title
                    as="h2"
                    className="text-uptop-red font-exposureItalic text-stroke text-3xl text-center"
                  >
                    Forbidden
                  </Dialog.Title>
                  <div className="mt-2 flex flex-col gap-2">
                    <p className="text-md text-black ">{forbiddenMessage}</p>
                    {forbiddenRedirect && (
                      <p className="text-md text-black flex flex-col">
                        For more info, refer to:{" "}
                        <a
                          href={forbiddenRedirect}
                          target="_blank"
                          rel="noopener noreferrer"
                          className="text-blue-600 hover:text-uptop-red"
                        >
                          {forbiddenRedirect}
                        </a>
                      </p>
                    )}
                  </div>

                  <div className="mt-4"></div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </>
  );
}
