import ArrowRightAltOutlined from "@mui/icons-material/ArrowRightAltOutlined";
import { Link } from "react-router-dom";

export default function NoPage() {
  return (
    <div className="justify-center items-center w-full text-center flex flex-col h-full bg-uptop-tan">
      <div className=" m-auto text-center flex flex-col gap-4 z-10">
        <img
          src={"./OG.svg"}
          height={200}
          width={300}
          className="mx-auto select-none rounded-lg"
          alt="Uptop OG"
        />
        <div className="border-b-2 border-primary-redDarker my-6" />
        <h1 className="text-primary-redDarker text-2xl capitalize ">
          404 - Page not found
        </h1>
        <h1 className="text-uptop-red text-2xl mx-auto animate-bounce group">
          <Link
            to="/"
            className="group-hover:text-primary-redDarker"
          >
            <span className="flex gap-2">
              <ArrowRightAltOutlined className="w-6 h-6 my-auto " />

              <span className="group-hover:text-primary-redDarker">
                Back to home
              </span>
            </span>
          </Link>
        </h1>
      </div>
    </div>
  );
}
