import EastOutlined from "@mui/icons-material/EastOutlined";
import CreditCardOutlined from "@mui/icons-material/CreditCardOutlined";
import LocalOfferOutlined from "@mui/icons-material/LocalOfferOutlined";
import { useUptop } from "../../contexts/UptopContext";
import { AllCommissions, IPromo } from "../../types";
import CashbackButton from "../Buttons/Cashback";
import ValidateCouponButton from "../Buttons/ValidateCoupon";
import TokenGate from "../DealPass/TokenGate";
import CustomImage from "../ReusableUI/CustomImage";
import UptopTable from "../UptopTable";
import CouponOfferStatus from "./CouponOfferStatus";

type Props = {
  specialPromos: IPromo[];
  setActivePromo: React.Dispatch<React.SetStateAction<IPromo | undefined>>;
};
export default function OffersDesktop({
  specialPromos,
  setActivePromo,
}: Props) {
  const { address, activatedOffers, earnedRewards, hasDealPass } = useUptop();
  return (
    <UptopTable
      headers={["Merchant", "Description", "Reward", "Status"]}
      data={specialPromos}
      renderRow={(offer: IPromo) => {
        const offerActive =
          offer.promoType === "cashback"
            ? activatedOffers.some((promo) => promo.promoId === offer.promoId)
            : false;

        const rewardTotal = offerActive
          ? earnedRewards.reduce((acc: number, reward: AllCommissions) => {
              if (reward.CommissionID === offer.promoId)
                acc += reward.CashbackAmount;
              return acc;
            }, 0)
          : 0;

        return (
          <tr
            key={offer.promoId}
            className={`${
              address && "hover:bg-surface-200 hover:cursor-pointer"
            } group`}
            onClick={() => {
              // Only show promo details if user is signed in
              if (address) {
                setActivePromo(offer);
              }
            }}
          >
            <td className="p-4">
              <div className="flex flex-row gap-4">
                <a
                  href={offer.merchantLink}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <CustomImage
                    imageURL={offer.image}
                    alt={offer.merchantName}
                    className="object-cover h-20 w-20 min-w-[5rem]  rounded-xl  shadow-uiBoxShadowMisc border-2 border-black"
                  />
                </a>
                <a
                  href={offer.merchantLink}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="font-exposure text-lg my-auto hover:underline"
                >
                  {offer.merchantName}
                </a>
              </div>
            </td>
            <td className="p-4 font-exposure text-lg">{offer.title}</td>

            <td className="p-4  ">
              {!address ? (
                <span className="font-exposure text-uptop-red capitalize">
                  Please sign in
                </span>
              ) : offer.promoType === "cashback" ? (
                <span className="font-exposure text-lg">
                  {rewardTotal.toFixed(4)}{" "}
                  {
                    offer?.rewardSymbol ?? "UNKNOWN" //Note: This is required for cashback, but adding this so we maintain type safety
                  }
                </span>
              ) : (
                <ValidateCouponButton offer={offer} />
              )}
            </td>
            <td className="p-4 font-exposure ">
              <TokenGate unlockCondition={hasDealPass}>
                {offer.promoType === "cashback" ? (
                  // Cashback offers
                  <CashbackButton
                    offer={offer}
                    offerActive={offerActive}
                  />
                ) : (
                  // Coupon offers
                  // User has attempted to validate a coupon, change status based on isHolder
                  <CouponOfferStatus offer={offer} />
                )}
              </TokenGate>
            </td>

            <td>
              <EastOutlined
                className={`${address && "group-hover:visible"} invisible`}
                fontSize="large"
              />
              {offer.promoType === "cashback" ? (
                <CreditCardOutlined
                  className={`${address && "group-hover:invisible"} visible`}
                />
              ) : (
                <LocalOfferOutlined
                  className={`${address && "group-hover:invisible"} visible`}
                />
              )}
            </td>
          </tr>
        );
      }}
    />
  );
}
