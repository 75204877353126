import PrimaryButton from "../Buttons/PrimaryButton";
import CustomImage from "../ReusableUI/CustomImage";

type OfferCardAffiliateProps = {
  title: string;
  category: string;
  subtitle: string;
  onClick: React.MouseEventHandler<HTMLButtonElement> | undefined;
  imageUrl?: string;
};
export default function OfferCardAffiliate({
  title,
  category,
  subtitle,
  onClick,
  imageUrl,
}: OfferCardAffiliateProps) {
  return (
    <div className="select-none flex flex-col flex-shrink-0 flex-grow-0   w-72 h-[400px] p-3 bg-uptop-tan rounded-xl border-2 border-black shadow-uiBoxShadow">
      <CustomImage
        className="w-full h-48 rounded-xl text-3xl object-cover border-2 border-black"
        imageURL={imageUrl}
        alt={title}
        useFallback={false}
      />
      <div className="flex flex-col mt-auto gap-2">
        <h3 className="text-lg font-bold font-exposure">{title}</h3>
        <div>
          {category ? (
            <p className="text-md text-gray-600  font-exposure ">
              {category} 🏷️
            </p>
          ) : (
            "\u00A0"
          )}
        </div>
        <p className="text-md text-gray-600  font-exposure">{subtitle}</p>
        <PrimaryButton
          onClick={onClick}
          className="w-full flex justify-center h-11  m-auto "
        >
          Get Deal
        </PrimaryButton>
      </div>
    </div>
  );
}
