import ArrowBack from "@mui/icons-material/ArrowBack";
import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import SecondaryButton from "../Buttons/SecondaryButton";
import TokenGate from "../DealPass/TokenGate";
import CustomImage from "../ReusableUI/CustomImage";
import { useUptop } from "../../contexts/UptopContext";
import { buildWildfireOfferLink } from "../../lib/wildfireLinkBuilder";
import { AffiliateOffer } from "../../types/wildfire";
import CouponTable from "./CouponTable";
import { useWindowSize } from "react-use";
import getSymbolFromCurrency from "currency-symbol-map";

type AffiliateOfferViewProps = {
  activeOffer: AffiliateOffer;
};
export default function AffiliateOfferView({
  activeOffer,
}: AffiliateOfferViewProps) {
  const navigate = useNavigate();
  const { width } = useWindowSize();
  const { deviceID, country, address, hasDealPass } = useUptop();
  const [searchTerm, setSearchTerm] = useState("");

  const wildfireMerchantId = activeOffer.ID;

  const offerURL =
    deviceID && wildfireMerchantId
      ? buildWildfireOfferLink({
          deviceID,
          wildfireMerchantId,
          preferredCountry: country,
          trackingCode: address,
        })
      : "";

  return (
    <div className="h-full w-full  flex flex-col  px-8 pb-8  2xl:px-24  ">
      <div className="flex flex-row my-4 gap-4 ">
        <button
          onClick={() => navigate(-1)}
          className="btn-reverse btn-white  group p-3 rounded-md  w-fit  my-auto"
        >
          <ArrowBack className="text-black w-8 h-8" />
        </button>
        <h1 className="font-exposure text-3xl capitalize my-auto">
          All Offers
        </h1>
        {activeOffer.coupons.length > 0 && width >= 1536 && (
          <div className="ml-auto">
            <input
              type="text"
              placeholder="Search"
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              className="rounded-full  border-2 border-black py-2 px-4 focus:shadow-uiBoxShadowMisc font-exposure outline-none"
            />
          </div>
        )}
      </div>
      <div className="mt-2 flex flex-col md:flex-row mx-auto gap-8 lg:gap-12 w-full  ">
        <CustomImage
          imageURL={activeOffer?.Images[0]?.URL}
          alt={activeOffer.Name}
          useFallback={false}
          className="h-64 w-64  md:h-80 md:w-80 text-3xl shadow-uiBoxShadow object-cover border-2  mx-auto md:mx-0 my-auto border-black rounded-xl"
        />

        <div className="flex flex-col  2xl:text-left  w-full lg:w-3/4">
          <div className="flex flex-col lg:flex-row    lg:mx-0 text-center 2xl:text-left  h-fit gap-2 ">
            <h1 className="text-xl md:text-2xl  mx-auto lg:ml-0 font-exposure my-auto ">
              {activeOffer.Name}
            </h1>
            {!offerURL ? (
              <TokenGate
                className=""
                customText="Login to start earning cash back"
                unlockCondition={!!offerURL}
              />
            ) : (
              <TokenGate
                className="ml-auto "
                unlockCondition={hasDealPass}
                customText="Please collect Deal Pass to start earning cash back"
              >
                {/* eslint-disable-next-line react/jsx-no-target-blank */}
                <a
                  href={offerURL}
                  target="_blank"
                  rel="noopener" // we WANT referrer information to be passed to the merchant to prevent issues with tracking
                  className=" "
                >
                  <SecondaryButton>
                    <span className="text-lg font-thin font-exposure tracking-wide my-auto ">
                      Get Deal
                    </span>
                  </SecondaryButton>
                </a>
              </TokenGate>
            )}
          </div>
          <div className="border-b-2 border-surface-300 py-6 2xl:py-0 2xl:my-6" />

          <div className="grid grid-cols-1 2xl:grid-cols-2 gap-2 2xl:gap-0 h-full ">
            <div className="flex flex-col">
              <h1 className="text-lg md:text-xl font-exposure mb-2">
                Offer Details
              </h1>
              <div className="flex flex-col space-y-2 h-fit max-h-[500px] overflow-y-auto  pb-2 pr-4 mx-auto w-11/12 ">
                {activeOffer.commissions
                  .filter((commission) => commission.Amount > 0)
                  .sort((a, b) => {
                    if (a.Kind === "PERCENTAGE" && b.Kind === "FLAT") {
                      return -1;
                    } else if (a.Kind === "FLAT" && b.Kind === "PERCENTAGE") {
                      return 1;
                    } else {
                      return b.Amount - a.Amount;
                    }
                  })
                  .map((commission) => {
                    return (
                      <div
                        key={commission.Name}
                        className="group max-w-lg font-exposure text-sm lg:text-md xl:text-lg rounded-lg py-2 px-4 border-2 border-black shadow-uiButtonBoxShadow  bg-white"
                      >
                        <div className="flex ">
                          <span className="w-3/4 capitalize  text-surface-700 font-exposure truncate overflow-hidden whitespace-nowrap group-hover:whitespace-normal group-hover:h-auto group-hover:overflow-visible hover:w-auto ">
                            {commission.Name}
                          </span>
                          <span className="text-black font-exposure ml-auto text-right my-auto">
                            {commission.Kind === "FLAT" &&
                              getSymbolFromCurrency(
                                commission.Currency ?? "USD",
                              )}
                            {commission.Amount}
                            {commission.Kind === "PERCENTAGE" && "%"}
                          </span>
                        </div>
                      </div>
                    );
                  })}
              </div>

              {activeOffer.PaysNewCustomersOnly && (
                <div className="bg-uptop-redTint/20 text-uptop-redTint rounded-full px-4 py-2 my-4 font-exposure w-fit text-sm lg:text-md xl:text-lg">
                  Pays New Customers Only
                </div>
              )}
            </div>

            <div>
              <h1 className="text-lg md:text-xl font-exposure mb-2 ">
                Instructions
              </h1>
              <ol className="list-decimal text-surface-700 space-y-2">
                <li className="list-item list-inside font-exposure">
                  Click "Get Deal"
                </li>
                <li className="list-item list-inside font-exposure">
                  Make your purchase at {activeOffer.Name}
                </li>
                <li className="list-item list-inside font-exposure">
                  Check the Transaction History tab in{" "}
                  <Link
                    to={"/MyWallet"}
                    className="font-exposure text-uptop-purple hover:text-surface-700"
                  >
                    My Wallet
                  </Link>{" "}
                  for status updates
                </li>
              </ol>
            </div>
          </div>
        </div>
      </div>{" "}
      <div className="border-b-2 border-surface-300 my-6" />
      <div className="relative">
        <h1 className="font-exposure text-2xl capitalize mb-2 ">
          Extra Deals 🤫
        </h1>
        {activeOffer.coupons.length > 0 && width <= 1536 && (
          <div className="text-center lg:text-right pb-4">
            <input
              type="text"
              placeholder="Search"
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              className="rounded-full  border-2 border-black py-2 px-4 focus:shadow-uiBoxShadowMisc font-exposure outline-none"
            />
          </div>
        )}
        <CouponTable
          allCoupons={activeOffer.coupons}
          searchTerm={searchTerm}
        />
      </div>
    </div>
  );
}
