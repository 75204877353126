import Joyride, { CallBackProps } from "react-joyride";
import { useNavigate } from "react-router-dom";
import { useMount } from "react-use";
import { useJoyrideContext } from "../contexts/JoyrideContext";

export default function JoyrideWrapper() {
  const {
    setState,
    state: { run, stepIndex, steps },
  } = useJoyrideContext();

  const navigate = useNavigate();

  const handleCallback = (data: CallBackProps) => {
    const { action, index, type } = data;

    if (type === "step:before" && index === 0) {
      navigate("/DailyOffers");
    }

    if (type === "step:after" && action === "next") {
      navigateAndRestartTour(index + 1);
    } else if (type === "step:after" && action === "prev" && index > 0) {
      navigateAndRestartTour(index - 1);
    } else if (
      action === "reset" ||
      action === "close" ||
      type === "tour:end"
    ) {
      setState({ run: false, stepIndex: 0, tourActive: false });
    }
  };

  function navigateAndRestartTour(newIndex: number) {
    switch (newIndex) {
      case 0:
        navigate("/DailyOffers");
        break;
      case 1:
        navigate("/SpecialOffers");
        break;
      case 2:
        navigate("/Challenges");
        break;
      case 3:
        navigate("/MyWallet");
        break;
      case 4:
        navigate("/Help");
        break;
    }
    setState({ run: true, stepIndex: newIndex });
  }

  useMount(() => {
    setState({
      steps: [
        {
          target: "#DailyOffers",
          content: (
            <>
              Earn crypto directly in your wallet when you shop through our
              expansive partner network. Shop more, earn more!
            </>
          ),
        },

        {
          target: "#SpecialOffers",
          content: (
            <>
              Unleash bonus cashback, access exclusive coupons, and explore more
              perks by linking your card. Treat yourself!
            </>
          ),
        },

        {
          target: "#Challenges",
          content: (
            <>
              Link your card, shop with qualifying merchants, and earn tradeable
              rewards like cashback vouchers. Challenge accepted?
            </>
          ),
        },

        {
          target: "#MyWallet",
          content: (
            <>
              Manage your payout currency, use and view your cashback vouchers,
              and keep track of your payout history, all in one place. Your
              finance hub!
            </>
          ),
        },

        {
          target: "#Help",
          content: (
            <>
              Need help understanding our payouts? This section has you covered.
              For anything else, join our Discord community. We're here for you!
            </>
          ),
        },
      ],
    });
  });

  return (
    <Joyride
      callback={handleCallback}
      continuous
      run={run}
      stepIndex={stepIndex}
      steps={steps}
      showProgress={true}
      styles={{
        options: {
          arrowColor: "#FFFCEF",
          backgroundColor: "#FFFCEF",
          primaryColor: "#DD3602",
          textColor: "#000000",
        },
        tooltipContent: {
          textAlign: "left",
        },
      }}
    />
  );
}
