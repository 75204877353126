import { KeyedMutator } from "swr";
import { OwnedNft } from "../../types/alchemy";
import { IReward, Voucher } from "../../types/challenges";
import ClaimReward from "../Challenges/ClaimReward";
import CustomImage from "../ReusableUI/CustomImage";

type RewardCardProps = {
  reward: OwnedNft;
  mutateRewards: KeyedMutator<IReward>;
  setChosenReward: React.Dispatch<
    React.SetStateAction<Voucher | OwnedNft | undefined>
  >;
};
export default function RewardCard({
  reward,
  mutateRewards,
  setChosenReward,
}: RewardCardProps) {
  if (!reward?.rawMetadata) return <></>;

  const { tokenId } = reward;
  const { image } = reward.rawMetadata;
  const { name, address } = reward.contract;

  return (
    <div className="w-64 group  flex flex-col gap-3 ">
      <div className="relative mb-1">
        <CustomImage
          imageURL={image}
          className="w-auto h-64 object-cover  rounded-lg shadow-uiBoxShadowCard border-2 border-black"
        />
      </div>
      <ClaimReward
        contractAddress={address}
        tokenId={tokenId}
        mutateRewards={mutateRewards}
      />
      <h1 className="text-xl font-exposure">{name}</h1>
      <div
        onClick={() => {
          setChosenReward(reward);
        }}
        className=" text-lg font-exposure underline hover:cursor-pointer text-surface-700 hover:text-black"
      >
        Learn More
      </div>
    </div>
  );
}
