import { NetworkName } from "../../constants";

type ViewCollectionProps = {
  contractAddress: string;
  network: NetworkName;
  text?: string;
};
export default function ViewCollection({
  contractAddress,
  network,
  text,
}: ViewCollectionProps) {
  const OPENSEA_URL =
    network === "mumbai"
      ? `https://testnets.opensea.io/assets/${network}`
      : `https://opensea.io/assets/${network}`;

  return (
    <a
      href={OPENSEA_URL + "/" + contractAddress}
      target="_blank"
      rel="noreferrer"
      className=" my-auto "
    >
      <button className="btn-reverse bg-uptop-purple rounded-xl h-16 py-2 w-full mx-auto justify-center flex flex-row  gap-4">
        <h3 className="my-auto text-white">{text ?? "View Collection"}</h3>
      </button>
    </a>
  );
}
