import { Link, useLocation } from "react-router-dom";
import ConnectButton from "../../../../components/Buttons/Connect";
import LinkCardButton from "../../../../components/Buttons/LinkCard";
import SecondaryButton from "../../../../components/Buttons/SecondaryButton";
import MintPassButton from "../../../../components/DealPass/MintPass";
import { useSidebarRouting } from "../../../../contexts/SidebarRoutingContext";
import { useUptop } from "../../../../contexts/UptopContext";
import { useUser } from "../../../../contexts/UserContext";
import Status from "../Status";
import { useWindowSize } from "react-use";

export default function NavbarMobileMenu() {
  const { address } = useUptop();
  const { handleDisconnect } = useUser();
  const { routes, toggleMenu, resetScrollPosOffers } = useSidebarRouting();
  const { width } = useWindowSize();
  const location = useLocation();
  const currentPath = location.pathname.split("/")[1];

  return (
    <div className=" h-full pt-20  bg-white flex flex-col items-center justify-center">
      <div className={`bg-white z-50 px-8  min-w-full  `}>
        {routes.map((route, idx) => {
          if (route.isMobile && width >= 1024) {
            return null;
          }
          return (
            <Link
              id={route.id}
              key={"large_" + route.pathName}
              to={route.path}
              onClick={() => {
                if (route.path === "DailyOffers") {
                  resetScrollPosOffers();
                }
                toggleMenu();
              }}
              className={` flex w-full gap-2 my-2 py-2 ${
                currentPath === route.path
                  ? " text-black pl-10  "
                  : "opacity-50 hover:bg-gray-200 "
              }`}
            >
              <div className=" h-8 w-full justify-start items-start gap-6 inline-flex ">
                <span className="font-exposure  text-black text-sm font-normal leading-3 tracking-wide ">
                  {`0${idx + 1}`}
                </span>
                <span className="font-exposure font-normal leading-relaxed text-xl tracking-wider">
                  {route.pathName}
                </span>
              </div>
            </Link>
          );
        })}
      </div>
      <div className=" flex flex-col mt-auto pb-8  lg:flex-row gap-4 ml-auto  min-w-full px-3 ">
        {!address ? (
          <ConnectButton />
        ) : (
          <div className="flex flex-col gap-6">
            {/* Manage Cards button and account status  */}
            <div className="grid grid-cols-2 col-auto gap-1">
              <LinkCardButton
                type="logo"
                className="w-full gap-1"
              />
              <Status />
            </div>

            <MintPassButton
              className="w-full py-4 font-exposureItalic font-normal my-auto justify-center  tracking-wide "
              style={{ borderRadius: "1000px" }}
            />

            <SecondaryButton
              id="Logout"
              className="py-4 font-exposureItalic font-normal my-auto justify-center text-2xl tracking-wide "
              onClick={handleDisconnect}
            >
              Logout
            </SecondaryButton>
          </div>
        )}
      </div>
    </div>
  );
}
