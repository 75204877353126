import "./index.css";
import axios from "axios";
import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import { REACT_APP_API_SERVER } from "./config";
import { JoyrideProvider } from "./contexts/JoyrideContext";
import { SidebarRoutingProvider } from "./contexts/SidebarRoutingContext";
import { UptopProvider } from "./contexts/UptopContext";
import { UserProvider } from "./contexts/UserContext";
import { Web3Provider } from "./contexts/Web3Context";
import reportWebVitals from "./reportWebVitals";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement,
);

axios.defaults.baseURL = REACT_APP_API_SERVER;

root.render(
  <React.StrictMode>
    <Web3Provider>
      <UserProvider>
        <UptopProvider>
          <JoyrideProvider>
            <SidebarRoutingProvider>
              <App />
            </SidebarRoutingProvider>
          </JoyrideProvider>
        </UptopProvider>
      </UserProvider>
    </Web3Provider>
  </React.StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
