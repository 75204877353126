import { useUptop } from "../../contexts/UptopContext";
import SecondaryButton from "./SecondaryButton";
import SimpleButton from "./SimpleButton";

type Props = {
  type: "logo" | "simple";
  className?: string;
};
export default function LinkCardButton({ type, className }: Props) {
  const { LaunchFinicity, connectURL } = useUptop();
  const text = !connectURL ? "Please Wait" : "Manage Cards";

  if (type === "logo") {
    return (
      <SecondaryButton
        className={className}
        id="LinkCard"
        disabled={!connectURL}
        outlined
        onClick={async (event) => {
          event.stopPropagation();
          LaunchFinicity();
        }}
      >
        <img
          src="/mastercard.svg"
          alt="Mastercard"
          className="w-8 h-8 my-auto  stroke-black stroke-2 "
        />
        <span className="flex flex-row m-auto font-exposureItalic font-bold text-xs lg:text-sm lg:pl-2 tracking-wide text-black">
          {text}
        </span>
      </SecondaryButton>
    );
  } else {
    return (
      <SimpleButton
        className={className}
        id="LinkCard"
        disabled={!connectURL}
        onClick={async (event) => {
          event.stopPropagation();
          LaunchFinicity();
        }}
      >
        <span className="px-2 flex flex-row my-auto font-exposure font-medium ">
          {text}
        </span>
      </SimpleButton>
    );
  }
}
