import { ButtonHTMLAttributes, ReactNode } from "react";

type SecondaryButtonProps = {
  children?: ReactNode;
  className?: string;
} & ButtonHTMLAttributes<HTMLButtonElement>;

export default function SecondaryButton({
  children,
  className,
  ...props
}: SecondaryButtonProps) {
  return (
    <button
      className={`${className} justify-center items-center btn-reverse btn-tan rounded-full flex flex-row gap-2 text-sm  group relative outline-none `}
      {...props}
    >
      {children}
    </button>
  );
}
