import { Popover, Transition } from "@headlessui/react";
import NotificationsActiveOutlined from "@mui/icons-material/NotificationsActiveOutlined";
import { Fragment } from "react";
import { useWindowSize } from "react-use";
import { useUptop } from "../../contexts/UptopContext";
import NotificationSettingsForm from "./Form";

type Props = {
  className?: string;
};

export default function NotificationSettingsPopover({ className }: Props) {
  const { email } = useUptop();
  const { width } = useWindowSize();

  const popOverPosition =
    width > 1024 ? "right-[-1rem] top-16" : "left-[1.5rem] top-28";

  return (
    <Popover className={className}>
      {({ open }) => (
        <>
          <Popover.Button
            className={`
                ${open ? "" : "text-opacity-90"}
                group btn-reverse btn-white rounded-lg flex gap-1 font-exposure capitalize font-thin relative`}
          >
            <NotificationsActiveOutlined className="my-auto" />
            {width > 1024 && "Notifications"}
            {!email && (
              <span className="w-4 h-4 rounded-full bg-uptop-red absolute top-[-4px] right-[-4px] animate-pulse" />
            )}
          </Popover.Button>
          <Transition
            as={Fragment}
            enter="transition ease-out duration-200"
            enterFrom="opacity-0 translate-y-1"
            enterTo="opacity-100 translate-y-0"
            leave="transition ease-in duration-150"
            leaveFrom="opacity-100 translate-y-0"
            leaveTo="opacity-0 translate-y-1"
          >
            <Popover.Panel
              className={`absolute ${popOverPosition} z-10 w-screen max-w-[21rem] lg:max-w-sm  bg-uptop-tan`}
            >
              <div className="overflow-hidden rounded-lg shadow-uiBoxShadow border-2 border-black p-8">
                <h1 className="font-exposure text-md ">
                  Get notified when your rewards are updated and new offers are
                  available 👇
                </h1>

                <div className="flex flex-col gap-4 mt-4">
                  <NotificationSettingsForm />
                </div>
              </div>
            </Popover.Panel>
          </Transition>
        </>
      )}
    </Popover>
  );
}
