import { createContext, useContext, useMemo } from "react";
import { useSetState } from "react-use";
import { Step } from "react-joyride";

export interface JoyrideState {
  run: boolean;
  stepIndex: number;
  steps: Step[];
  tourActive: boolean;
}
type JoyrideContextType = {
  state: JoyrideState;
  setState: (
    patch:
      | Partial<JoyrideState>
      | ((prevState: JoyrideState) => Partial<JoyrideState>),
  ) => void;
};
const joyrideState: JoyrideState = {
  run: false,
  stepIndex: 0,
  steps: [],
  tourActive: false,
};

export const JoyrideContext = createContext({} as JoyrideContextType);

JoyrideContext.displayName = "JoyrideContext";

export function JoyrideProvider(props: any) {
  const [state, setState] = useSetState(joyrideState);

  const value = useMemo(
    () => ({
      state,
      setState,
    }),
    [setState, state],
  );

  return (
    <JoyrideContext.Provider
      value={value}
      {...props}
    />
  );
}

export function useJoyrideContext(): {
  setState: (
    patch:
      | Partial<JoyrideState>
      | ((previousState: JoyrideState) => Partial<JoyrideState>),
  ) => void;
  state: JoyrideState;
} {
  const context = useContext(JoyrideContext);

  if (!context) {
    throw new Error("useJoyrideContext must be used within an JoyrideProvider");
  }

  return context;
}
