import Confetti from "react-confetti";
import { Outlet } from "react-router-dom";
import { Slide, ToastContainer } from "react-toastify";
import { useWindowSize } from "react-use";
import ForbiddenModal from "../../components/ForbiddenModal";
import JoyrideWrapper from "../../components/JoyrideWrapper";
import { useUptop } from "../../contexts/UptopContext";
import Navbar from "./Navigation/Navbar";
import Sidebar from "./Navigation/Desktop/Sidebar";
import NavbarMobileMenu from "./Navigation/Mobile/Menu";
import { useSidebarRouting } from "../../contexts/SidebarRoutingContext";

export default function Layout() {
  const { width, height } = useWindowSize();
  const { forbiddenMessage, activateConfetti } = useUptop();
  const { showMenu } = useSidebarRouting();

  if (forbiddenMessage) {
    return <ForbiddenModal />;
  }

  return (
    <>
      <div className="h-screen flex flex-col ">
        <Navbar />
        <div className="flex flex-grow flex-col lg:flex-row overflow-hidden  relative ">
          {/* Only show sidebar for lg and bigger */}
          {width >= 1024 && <Sidebar />}
          <div
            className={`${
              width < 1024 && showMenu ? "hidden" : "block"
            }  overflow-y-auto flex-auto mt-4`}
          >
            <Outlet />
          </div>
          {width < 1024 && showMenu && <NavbarMobileMenu />}
          <Confetti
            width={width}
            height={height}
            numberOfPieces={activateConfetti ? 200 : 0}
          />
          <ToastContainer transition={Slide} />
        </div>
      </div>
      <JoyrideWrapper />
    </>
  );
}
