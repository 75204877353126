import LaunchOutlined from "@mui/icons-material/LaunchOutlined";
import { BLOCK_EXPLORER_URL } from "../../config";
import { OwnedNft } from "../../types/alchemy";
import { Voucher } from "../../types/challenges";
import CustomImage from "../ReusableUI/CustomImage";

type VoucherCardProps = {
  nftAddress: string;
  voucher: Voucher;
  setChosenReward: React.Dispatch<
    React.SetStateAction<Voucher | OwnedNft | undefined>
  >;
};
export default function VoucherCard({
  nftAddress,
  voucher,
  setChosenReward,
}: VoucherCardProps) {
  return (
    <>
      <div className="w-64 group   flex flex-col gap-3 ">
        <div className="relative mb-1">
          <CustomImage
            imageURL={voucher.image}
            className="w-auto h-64 object-cover  rounded-lg shadow-uiBoxShadowCard border-2 border-black"
          />
          <div className="absolute bg-uptop-red top-2 left-2 border-2 border-black rounded-lg shadow-uiButtonBoxShadow font-exposure text-white px-2 py-1 ">
            Burned
          </div>
          <a
            href={BLOCK_EXPLORER_URL + "/address/" + nftAddress}
            target="_blank"
            rel="noreferrer noopener"
            className="absolute btn-reverse btn-white shadow-uiButtonBoxShadow p-1 top-2 right-2 rounded-lg "
          >
            <LaunchOutlined />
          </a>
        </div>
        <button
          disabled={true}
          className="w-full btn-reverse btn-purple  disabled:bg-surface-300 disabled:text-surface-600 disabled:shadow-uiBoxShadowCard   disabled:cursor-not-allowed mt-1 my-auto text-white  px-4 md:px-8  rounded-md "
        >
          Claimed
        </button>
        <h1 className="text-xl font-exposure">
          ${voucher.creditRemaining} Remaining
        </h1>
        <div
          onClick={() => {
            setChosenReward(voucher);
          }}
          className=" text-lg font-exposure underline hover:cursor-pointer text-surface-700 hover:text-black"
        >
          Learn More
        </div>
      </div>
    </>
  );
}
