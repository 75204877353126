import { ButtonHTMLAttributes, ReactNode } from "react";

type SecondaryButtonProps = {
  children?: ReactNode;
  className?: string;
  outlined?: boolean;
} & ButtonHTMLAttributes<HTMLButtonElement>;

export default function SecondaryButton({
  children,
  className,
  outlined,
  ...props
}: SecondaryButtonProps) {
  return (
    <button
      className={`btn ${
        outlined ? "btn-purple-outlined" : "btn-purple"
      } flex flex-row  text-sm h-12   w-auto group relative outline-none ${className} `}
      {...props}
    >
      {children}
    </button>
  );
}
