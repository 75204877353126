import { useWindowSize } from "react-use";
import { useUptop } from "../../contexts/UptopContext";
import { IPromo } from "../../types";
import Badge from "../ReusableUI/Badge";
type CouponOfferStatusProps = { offer: IPromo };
export default function CouponOfferStatus({ offer }: CouponOfferStatusProps) {
  const { address, couponOfferStatuses } = useUptop();
  const { width } = useWindowSize();
  const promoId = offer.promoId;
  const couponStateForUser = couponOfferStatuses[address] ?? {};
  const hasTriedVerifying = promoId in couponStateForUser;
  const isHolder = hasTriedVerifying
    ? couponStateForUser[promoId].isHolder
    : false;

  const color = !hasTriedVerifying ? "orange" : isHolder ? "green" : "red";
  let text;
  if (!hasTriedVerifying) {
    let collectionName = offer.gateTokenName;
    // Truncate collection name if it's too long on mobile since we can't use hover
    if (width < 1024) {
      collectionName =
        collectionName.length > 18
          ? collectionName.slice(0, 18) + "..."
          : collectionName;
    }
    text = `${collectionName} Pending`;
  } else {
    text = isHolder ? "Holder" : "Not A Holder";
  }
  return (
    <Badge
      color={color}
      text={text}
      width="w-fit lg:w-52 lg:truncate  hover:overflow-visible hover:whitespace-normal "
    />
  );
}
