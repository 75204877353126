import LocalOfferOutlined from "@mui/icons-material/LocalOfferOutlined";
import { useUptop } from "../../contexts/UptopContext";
import { AllCommissions, IPromo } from "../../types";
import CashbackButton from "../Buttons/Cashback";
import ValidateCouponButton from "../Buttons/ValidateCoupon";
import TokenGate from "../DealPass/TokenGate";
import CustomImage from "../ReusableUI/CustomImage";
import CouponOfferStatus from "./CouponOfferStatus";
import ChevronRightOutlined from "@mui/icons-material/ChevronRightOutlined";
import CreditCardOutlined from "@mui/icons-material/CreditCardOutlined";
type Props = {
  specialPromos: IPromo[];
  setActivePromo: React.Dispatch<React.SetStateAction<IPromo | undefined>>;
};

export default function OffersMobile({ specialPromos, setActivePromo }: Props) {
  const { address, activatedOffers, earnedRewards, hasDealPass } = useUptop();

  return (
    <div className="w-full  flex flex-col gap-4">
      {specialPromos.map((offer) => {
        const offerActive =
          offer.promoType === "cashback"
            ? activatedOffers.some((promo) => promo.promoId === offer.promoId)
            : false;

        const rewardTotal =
          offer.promoType === "cashback"
            ? earnedRewards.reduce((acc: number, reward: AllCommissions) => {
                if (reward.CommissionID === offer.promoId)
                  acc += reward.CashbackAmount;
                return acc;
              }, 0)
            : 0;

        return (
          <div
            className="w-full bg-white border-2 border-black shadow-uiBoxShadowMisc rounded-xl text-left"
            key={offer.promoId}
          >
            <div className="p-4">
              <div className="flex flex-row gap-4">
                <CustomImage
                  imageURL={offer.image}
                  alt={offer.merchantName}
                  className="object-cover h-20 w-20 min-w-[5rem] rounded-xl  shadow-uiBoxShadowMisc border-2 border-black"
                />

                <div className="font-exposure text-2xl ml-auto my-auto text-right flex flex-col gap-1">
                  {offer.merchantName}
                  <TokenGate unlockCondition={hasDealPass}>
                    {offer.promoType === "cashback" ? (
                      // Cashback offers
                      <CashbackButton
                        offer={offer}
                        offerActive={offerActive}
                      />
                    ) : (
                      // Coupon offers
                      // User has attempted to validate a coupon, change status based on isHolder
                      <CouponOfferStatus offer={offer} />
                    )}
                  </TokenGate>
                </div>
              </div>
            </div>

            <div className="pl-4 ">
              <div className="flex flex-col gap-2    ">
                <div className="text-surface-700 text-sm font-exposure">
                  Promotion
                </div>
                <div className="font-exposure text-base"> {offer.title}</div>
              </div>
            </div>
            <div className="pl-4 pt-4 font-exposure text-lg">
              <div className="flex flex-col gap-2    ">
                <div className="text-surface-700 text-sm font-exposure">
                  Type
                </div>
                {offer.promoType === "cashback" ? (
                  <div className="font-exposure flex flex-row gap-1 text-base">
                    <CreditCardOutlined className="my-auto" />
                    Card Linked
                  </div>
                ) : (
                  <div className="font-exposure flex flex-row gap-1 text-base">
                    <LocalOfferOutlined className="my-auto" />
                    Coupon Code
                  </div>
                )}
              </div>
            </div>

            <div className="p-4 font-exposure text-lg flex flex-row">
              {!address ? (
                <span className="font-exposure text-uptop-red capitalize">
                  Please sign in
                </span>
              ) : offer.promoType === "cashback" ? (
                <div className="flex flex-col gap-1">
                  <div className="text-surface-700 text-sm font-exposure">
                    {offer.rewardSymbol} Earned
                  </div>
                  <div className="font-exposure">{rewardTotal.toFixed(4)}</div>
                </div>
              ) : (
                <ValidateCouponButton offer={offer} />
              )}

              {/* CTA to open modal */}
              <ChevronRightOutlined
                onClick={() => {
                  // Only show promo details if user is signed in
                  if (address) {
                    setActivePromo(offer);
                  }
                }}
                className="btn-reverse btn-purple  p-0 cursor-pointer rounded-full ml-auto my-auto"
                fontSize="large"
              />
            </div>
          </div>
        );
      })}
    </div>
  );
}
