import { OwnedNft } from "../../../types/alchemy";

type RewardDetailsProps = { reward: OwnedNft };
export default function RewardDetails({ reward }: RewardDetailsProps) {
  const description = reward.rawMetadata?.description;
  const merchants = reward.rawMetadata?.partnerMerchants ?? [];
  const categories = reward.rawMetadata?.categories ?? [];

  const hasCategories = categories.length > 0;

  return (
    <div className="flex flex-col gap-2 ">
      <div className="flex flex-col gap-1">
        <div className="text-surface-700 text-sm font-exposure">
          Description
        </div>
        <div className="font-exposure">{description}</div>
      </div>

      {hasCategories ? (
        <div className="flex flex-col gap-1">
          <div className="text-surface-700 text-sm font-exposure">
            Supported Categories
          </div>
          <ul className="list-inside list-disc">
            {categories.map((category: string) => {
              return (
                <li
                  className="font-exposure"
                  key={category}
                >
                  {category}
                </li>
              );
            })}
          </ul>
        </div>
      ) : (
        <div className="flex flex-col gap-1">
          <div className="text-surface-700 text-sm font-exposure">
            Supported Partners
          </div>
          <ul className="list-inside list-disc">
            {merchants.map((merchant: string) => {
              return (
                <li
                  className="font-exposure"
                  key={merchant}
                >
                  {merchant}
                </li>
              );
            })}
          </ul>
        </div>
      )}
    </div>
  );
}
