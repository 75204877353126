// To support more networks, add them here and in server/lib/nfts/alchemy.ts
export type NetworkName =
  | "ethereum"
  | "polygon"
  | "mumbai";

export const BLOCK_EXPLORER_URLS: Record<NetworkName, string> = {
  polygon: "https://polygonscan.com",
  mumbai: "https://mumbai.polygonscan.com",
  ethereum: "https://etherscan.io",
};
