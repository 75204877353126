// import Layout from "./components/Layout";
import { injectStyle } from "react-toastify/dist/inject-style";

import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import DailyOffers from "./pages/DailyOffers";
import Help from "./pages/Help";
import Layout from "./pages/Layout/Layout";
import MyWallet from "./pages/MyWallet";
import NoPage from "./pages/NoPage";
import SpecialOffers from "./pages/SpecialOffers";
import Challenges from "./pages/Challenges";
import YourAccount from "./pages/YourAccount";

if (typeof window !== "undefined") {
  injectStyle();
}

export default function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route
          path="/"
          element={<Layout />}
        >
          <Route
            index
            element={<Navigate to="/DailyOffers" />}
          />
          <Route
            path="DailyOffers/:Id?"
            element={<DailyOffers />}
          />
          <Route
            path="SpecialOffers"
            element={<SpecialOffers />}
          />
          <Route
            path="Challenges"
            element={<Challenges />}
          />
          <Route
            path="MyWallet"
            element={<MyWallet />}
          />
          <Route
            path="Help"
            element={<Help />}
          />
          <Route
            path="YourAccount"
            element={<YourAccount />}
          />
          <Route
            path="*"
            element={<NoPage />}
          />
        </Route>
      </Routes>
    </BrowserRouter>
  );
}
