
export function calculatePromotionDates(startDate: number, daysActive: number) {
  const today = new Date();
  const start = new Date(startDate);
  const diff = today.getTime() - start.getTime();
  const days = Math.ceil(diff / (1000 * 60 * 60 * 24));

  const daysRemaining = daysActive - days;
  const promotionEndDate = new Date(
    start.getTime() + daysActive * 24 * 60 * 60 * 1000
  );

  return { daysRemaining, promotionEndDate };
}
