import { Dialog, Transition } from "@headlessui/react";
import ArrowBack from "@mui/icons-material/ArrowBack";
import { Fragment } from "react";
import { Link } from "react-router-dom";
import { formatDateTime } from "../../lib/util";
import { calculatePromotionDates } from "../../lib/challenges/calculatePromotionDates";
import { EarnedChallenge } from "../../types/challenges";
import CustomImage from "../ReusableUI/CustomImage";
import ViewCollection from "../ReusableUI/ViewCollection";
import { IS_PROD } from "../../config";

type ChallengeModalProps = {
  isOpen: boolean;
  activeChallenge: EarnedChallenge | undefined;
  deactivateChallenge: () => void;
};
export default function ChallengeModal({
  isOpen,
  activeChallenge,
  deactivateChallenge,
}: ChallengeModalProps) {
  if (!activeChallenge) {
    return <></>;
  }

  const totalSpentColor =
    activeChallenge.progress < 30
      ? "text-red-700"
      : activeChallenge.progress < 60
      ? "text-red-500"
      : activeChallenge.progress < 100
      ? "text-orange-600"
      : "text-green-600";

  const { daysRemaining, promotionEndDate } = calculatePromotionDates(
    activeChallenge.startDate,
    activeChallenge.daysActive,
  );

  const daysRemainingColor =
    daysRemaining < 5
      ? "text-red-600"
      : daysRemaining < 10
      ? "text-yellow-600"
      : "text-green-600";

  const readyToClaim =
    activeChallenge.progress === 100 && !activeChallenge.isClaimed;
  const spendLeft = activeChallenge.minimumSpend - activeChallenge.totalSpent;
  return (
    <>
      <Transition
        appear
        show={isOpen}
        as={Fragment}
      >
        <Dialog
          as="div"
          className="relative z-10"
          onClose={() => {}}
        >
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-uptop-tan bg-opacity-100" />
          </Transition.Child>

          <div className="fixed inset-0 top-16 md:top-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="flex flex-col gap-8 w-full max-w-4xl xl:max-w-6xl  min-h-[600px] transform overflow-hidden rounded-lg  p-2 text-left  transition-all">
                  <div className="flex flex-row md:gap-4 mt-6 mb-2">
                    <button
                      onClick={deactivateChallenge}
                      className="btn-reverse btn-white  group p-3 rounded-md  w-fit  my-auto"
                    >
                      <ArrowBack className="text-black w-8 h-8" />
                    </button>
                    <Dialog.Title
                      as="h2"
                      className="w-full  my-auto font-exposure text-3xl text-center md:text-left "
                    >
                      {activeChallenge.rewardName} Challenge
                    </Dialog.Title>
                  </div>
                  <div className="ml-4  flex-row md:gap-20 hidden md:flex ">
                    <div className="flex flex-col font-exposureItalic text-2xl ">
                      Your Spend
                      <span
                        className={`text-3xl mt-1  font-exposure ${totalSpentColor}`}
                      >
                        ${activeChallenge.totalSpent}
                      </span>
                    </div>
                    <div className="flex flex-col font-exposureItalic text-2xl ">
                      Minimum Spend
                      <span className="text-3xl  mt-1  font-exposure text-uptop-purple ">
                        ${activeChallenge.minimumSpend}
                      </span>
                    </div>
                    {!activeChallenge.isActive ? (
                      <div className="flex flex-col font-exposureItalic text-2xl ">
                        Ended On
                        <span className={`text-2xl mt-1 font-exposure`}>
                          {formatDateTime(promotionEndDate.toISOString())}
                        </span>
                      </div>
                    ) : (
                      <div className="flex flex-col font-exposureItalic text-2xl ">
                        Days Left
                        <span
                          className={`text-3xl mt-1 font-exposure ${daysRemainingColor}`}
                        >
                          {daysRemaining}
                        </span>
                      </div>
                    )}
                    <div className="flex flex-col font-exposureItalic text-2xl ">
                      Reward
                      {readyToClaim ? (
                        <Link to={"/MyWallet"}>
                          <button
                            data-testid="claim-button"
                            className="btn-reverse btn-purple  disabled:cursor-not-allowed  h-14 mt-1 my-auto text-white py-1 px-4 md:px-8  rounded-md "
                          >
                            Claim
                          </button>
                        </Link>
                      ) : activeChallenge.isActive ? (
                        <span className={`text-xl mt-2   font-exposure `}>
                          ${spendLeft} Remaining
                        </span>
                      ) : (
                        <span
                          className={`text-2xl mt-1   font-exposure text-uptop-red`}
                        >
                          Expired
                        </span>
                      )}
                    </div>
                  </div>
                  <div className=" flex flex-col md:flex-row gap-4 md:gap-12 mx-auto w-full   ">
                    <CustomImage
                      imageURL={activeChallenge.coverPhoto}
                      alt={activeChallenge.rewardId}
                      className=" object-cover h-56 w-full md:h-72 md:w-72  shadow-uiBoxShadowMisc border-2 border-black  mx-auto md:mx-0 rounded-xl"
                    />
                    <div className="gap-4  grid-rows-2 grid-cols-2  grid md:hidden ">
                      <div className="flex flex-col font-exposureItalic text-lg">
                        Your Spend
                        <span
                          className={`text-xl mt-2  font-exposure ${totalSpentColor} `}
                        >
                          ${activeChallenge.totalSpent}
                        </span>
                      </div>
                      <div className="flex flex-col font-exposureItalic text-lg">
                        Minimum Spend
                        <span className="text-xl mt-2  font-exposure text-uptop-purple ">
                          ${activeChallenge.minimumSpend}
                        </span>
                      </div>
                      <div className="flex  flex-col font-exposureItalic text-lg">
                        Days Left
                        <span
                          className={`text-xl mt-2 font-exposure ${daysRemainingColor}`}
                        >
                          {daysRemaining}
                        </span>
                      </div>
                      <div className="flex flex-col font-exposureItalic text-lg ">
                        Reward
                        {readyToClaim ? (
                          <Link to={"/MyWallet"}>
                            <button className="btn-reverse btn-purple  disabled:cursor-not-allowed  h-14 mt-1 my-auto text-white py-1 px-4 md:px-8  rounded-md ">
                              Claim
                            </button>
                          </Link>
                        ) : (
                          <span className={`text-md mt-2   font-exposure `}>
                            ${spendLeft} Remaining
                          </span>
                        )}
                      </div>
                    </div>

                    <div className="border-b-2 border-black" />

                    <div className="flex flex-col gap-4 my-auto">
                      <div>
                        <h1 className="text-xl font-exposure ">Task</h1>
                        <span className="text-md font-exposure text-surface-700">
                          {activeChallenge.description}
                        </span>
                      </div>
                      {activeChallenge?.qualifyingCategories &&
                      activeChallenge.qualifyingCategories.length ? (
                        <div>
                          <h1 className="text-xl font-exposure ">
                            Qualifying Categories
                          </h1>

                          <span className="text-md text-surface-700">
                            <ul className="grid  grid-cols-1 md:grid-cols-2 ">
                              {activeChallenge.qualifyingCategories.map(
                                (category) => (
                                  <li
                                    className="list-disc list-inside font-exposure "
                                    key={category}
                                  >
                                    {category}
                                  </li>
                                ),
                              )}
                            </ul>
                          </span>
                        </div>
                      ) : (
                        <div>
                          <h1 className="text-xl font-exposure ">
                            Qualifying Merchants
                          </h1>

                          <span className="text-md text-surface-700">
                            <ul className="grid  grid-cols-1 md:grid-cols-2">
                              {activeChallenge.qualifyingMerchants.map(
                                (category) => (
                                  <li
                                    className="list-disc list-inside font-exposure "
                                    key={category}
                                  >
                                    {category}
                                  </li>
                                ),
                              )}
                            </ul>
                          </span>
                        </div>
                      )}

                      <ViewCollection
                        contractAddress={activeChallenge.nftContractAddress}
                        network={IS_PROD ? "polygon" : "mumbai"}
                      />
                    </div>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </>
  );
}
