import {
  ConnectCancelEvent,
  ConnectDoneEvent,
  ConnectErrorEvent,
  ConnectEventHandlers,
  ConnectOptions,
  ConnectRouteEvent,
  FinicityConnect,
} from "@finicity/connect-web-sdk";
import axios from "axios";
import {
  ReactNode,
  createContext,
  useContext,
  useEffect,
  useState,
} from "react";
import { useCookies } from "react-cookie";
import { decodeToken } from "react-jwt";
import { toast } from "react-toastify";
import useSWR from "swr";
import { IS_PROD } from "../config";
import { APP_ENDPOINTS, FINICITY_ENDPOINTS } from "../endpoints";
import {
  ActivatedOffer,
  AllCommissions,
  ConnectUrlRes,
  CouponStausesState,
  MintRes,
  UserData,
  UserDataPayloadString,
  ValidateCouponResponse,
  VerifyNFTHolder,
} from "../types";
import { useUser } from "./UserContext";

interface UptopContextType extends UserData {
  forbiddenMessage: string;
  forbiddenRedirect: string;

  AuthenticatedFetcher: (url: string, token: string) => Promise<any>;

  address: string;
  email: string;
  notificationStatus: boolean;
  connectURL: string;
  userToken: string;
  activatedOffers: ActivatedOffer[];
  mintedUptop: boolean;
  deviceID: number;
  trackingCode: string;
  hasDealPass: boolean;
  hasLinkedCard: boolean;
  country: string;
  payoutPreferenceId: string;
  payoutPreferenceSymbol: string;

  earnedRewards: AllCommissions[];

  activateConfetti: boolean;
  MintDealPass: () => Promise<void>;
  ClaimReward: (contractAddress: string, tokenId: string) => Promise<string>;

  couponOfferStatuses: CouponStausesState;
  updateCouponOfferStauses: (
    promoId: string,
    isHolder: boolean,
    couponCode: string,
  ) => void;
  ValidateCoupon: (promo: string) => Promise<ValidateCouponResponse>;

  ActivateOffer: (promo: string) => Promise<boolean>;

  UpdateEmail: (
    email: string,
    notificationsEnabled: boolean,
  ) => Promise<string>;
  UpdatePayoutPreference: (payoutId: string) => Promise<void>;
  LaunchFinicity: () => Promise<void>;
}

type UptopProviderProps = {
  children: ReactNode;
};

const UptopContext = createContext<UptopContextType | undefined>(undefined);
export function UptopProvider({ children }: UptopProviderProps) {
  const { user, handleDisconnect, signature } = useUser();
  const [userToken, setUserToken] = useState("");
  const [address, setAddress] = useState(""); // To prevent flashing unauthorized content, we can setAddress to account when the rest of our data is loaded
  const [email, setEmail] = useState("");
  const [notificationStatus, setNotificationStatus] = useState(false);
  const [activatedOffers, setActivatedOffers] = useState<ActivatedOffer[]>([]);
  const [connectURL, setConnectURL] = useState("");
  const [mintedUptop, setMintedUptop] = useState(false);
  const [deviceID, setDeviceID] = useState(0);
  const [trackingCode, setTrackingCode] = useState("");
  const [country, setCountry] = useState("US");
  const [hasLinkedCard, setHasLinkedCard] = useState(false);
  const [hasDealPass, setHasDealPass] = useState(false);
  const [payoutPreferenceId, setPayoutPreferenceId] = useState("");
  const [payoutPreferenceSymbol, setPayoutPreferenceSymbol] = useState("");

  // Initialize coupon statuses state from cookies
  const [cookies, setCookie, removeCookie] = useCookies([
    "offerStatuses",
    "userToken",
  ]);
  const [couponOfferStatuses, setCouponOfferStatuses] =
    useState<CouponStausesState>(() => {
      const savedStatuses = cookies.offerStatuses ?? {};
      return savedStatuses;
    });

  const [activateConfetti, setActivateConfetti] = useState(false);

  const [forbiddenMessage, setForbiddenMessage] = useState("");
  const [forbiddenRedirect, setForbiddenRedirect] = useState("");

  const updateCouponOfferStauses = (
    promoId: string,
    isHolder: boolean,
    couponCode: string,
  ) => {
    setCouponOfferStatuses((prevStatuses) => {
      const newStatuses = { ...prevStatuses };

      if (!newStatuses[address]) {
        newStatuses[address] = {};
      }

      const expiryDate = new Date();
      expiryDate.setHours(expiryDate.getHours() + 1);

      newStatuses[address][promoId] = { isHolder, couponCode };
      setCookie("offerStatuses", JSON.stringify(newStatuses), {
        expires: expiryDate,
        secure: IS_PROD,
      });
      return newStatuses;
    });
  };

  async function AuthenticatedFetcher(url: string, token: string) {
    try {
      const res = await axios.get(url, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      return res.data;
    } catch (error) {
      if (axios.isAxiosError(error) && error.response?.status === 401) {
        await handleDisconnect(); // JWT expired, have user re-authenticate
        toast.error("You have been signed out, please sign back in.");
      }
    }
  }
  const fetchUserData = async (
    url: string,
    account: string | null,
    signature: string | null,
  ) => {
    try {
      if (!account || !signature) {
        throw new Error("No Account Or Signature Found");
      }

      const userDataString = await axios.post<UserDataPayloadString>(url, {
        address: account,
        signedMessage: signature,
      });

      return userDataString.data;
    } catch (error) {
      if (axios.isAxiosError(error) && error.response?.status === 401) {
        await handleDisconnect(); // JWT expired, have user re-authenticate
        toast.error("You have been signed out, please sign back in.");
      } else if (axios.isAxiosError(error) && error.response?.status === 403) {
        const { message, redirectURL } = error.response?.data ?? {
          message: undefined,
          redirectURL: undefined,
        };
        setForbiddenRedirect(redirectURL);
        setForbiddenMessage(message);
      }
      throw error;
    }
  };

  const { data: earnedRewards, mutate: mutateRewards } = useSWR<
    AllCommissions[]
  >(
    userToken ? APP_ENDPOINTS.FETCH_HISTORY : null,
    (url: string) => AuthenticatedFetcher(url, userToken),
    { focusThrottleInterval: 30000 },
  );

  const { data: userDataString, mutate: mutateUser } =
    useSWR<UserDataPayloadString>(
      user && signature ? APP_ENDPOINTS.FETCH_CUSTOMER : null,
      (url: string) => fetchUserData(url, user, signature),
      {
        focusThrottleInterval: 30000,
      },
    );

  // Claim Reward
  const ClaimReward = async (contractAddress: string, tokenId: string) => {
    const toastId = toast.loading("Claiming offer, please wait...");
    let txHash = "";
    try {
      if (!address || !userToken) {
        throw new Error("No account found");
      }

      const mintRes = await axios.post<MintRes>(
        APP_ENDPOINTS.CLAIM_REWARD,
        { contractAddress, tokenId },
        {
          headers: {
            Authorization: `Bearer ${userToken}`,
          },
        },
      );
      txHash = mintRes.data.txHash;
      if (txHash) {
        setActivateConfetti(true);
        toast.update(toastId, {
          render: "Offer claimed!",
          type: "success",
          isLoading: false,
          autoClose: 8000,
        });
        await mutateRewards();
        setTimeout(() => {
          setActivateConfetti(false);
        }, 10000);
      } else {
        toast.update(toastId, {
          render: "Couldn't claim your reward, please try again later.",
          type: "error",
          isLoading: false,
          autoClose: 8000,
        });
      }
    } catch (error) {
      console.log(
        error instanceof Error ? error.message : "Failed to claim reward",
      );
      if (axios.isAxiosError(error) && error.response?.status === 401) {
        await handleDisconnect(); // JWT expired, have user re-authenticate
        toast.update(toastId, {
          render: "You have been signed out, please sign back in.",
          type: "error",
          isLoading: false,
          autoClose: 8000,
        });
      }
    }
    return txHash;
  };
  // Mint deal pass
  const MintDealPass = async () => {
    try {
      if (!address || !userToken) {
        throw new Error("No account found");
      }

      const mintRes = await axios.post<MintRes>(
        APP_ENDPOINTS.MINT_DEAL_PASS,
        undefined,
        {
          headers: {
            Authorization: `Bearer ${userToken}`,
          },
        },
      );
      if (mintRes.data.txHash) {
        await mutateUser();
        setActivateConfetti(true);

        setTimeout(() => {
          setActivateConfetti(false);
        }, 10000);
      } else {
        toast.error("Couldn't mint your deal pass, please try again later.");
      }
    } catch (error) {
      console.log(
        error instanceof Error ? error.message : "Failed to mint deal pass",
      );
      if (axios.isAxiosError(error) && error.response?.status === 401) {
        await handleDisconnect(); // JWT expired, have user re-authenticate
        toast.error("You have been signed out, please sign back in.");
      }
    }
  };
  // Validate a coupon
  const ValidateCoupon = async (
    promo: string,
  ): Promise<ValidateCouponResponse> => {
    let couponCode = "";
    let isHolder = false;

    try {
      if (!address || !userToken) {
        throw new Error("No account found");
      }

      const verifyHolderRes = await axios.post<ValidateCouponResponse>(
        APP_ENDPOINTS.VALIDATE_COUPON,
        { promo },
        {
          headers: {
            authorization: `Bearer ${userToken}`,
          },
        },
      );
      if (verifyHolderRes.data.isHolder) {
        couponCode = verifyHolderRes.data.couponCode;
        isHolder = true;
      } else {
        toast.error("You are not eligible for this offer.");
      }
    } catch (error) {
      console.log(
        error instanceof Error ? error.message : "Failed to activate offer",
      );
      if (axios.isAxiosError(error) && error.response?.status === 401) {
        toast.error("You have been signed out, please sign back in.");
        await handleDisconnect(); // JWT expired, have user re-authenticate
      }
    }
    return { isHolder, couponCode };
  };
  // Cashback promo activation -> Adds to user's activatedOffers and allows them to earn cashback
  const ActivateOffer = async (promo: string) => {
    try {
      if (!address || !userToken) {
        throw new Error("No account found");
      }
      const verifyHolderRes = await axios.post<VerifyNFTHolder>(
        APP_ENDPOINTS.ACTIVATE_PROMO,
        { promo },
        {
          headers: {
            authorization: `Bearer ${userToken}`,
          },
        },
      );
      if (verifyHolderRes.data.isHolder) {
        await mutateUser();
        return true;
      } else {
        toast.error("You are not eligible for this offer.");
      }
    } catch (error) {
      console.log(
        error instanceof Error ? error.message : "Failed to activate offer",
      );
      if (axios.isAxiosError(error) && error.response?.status === 401) {
        toast.error("You have been signed out, please sign back in.");
        await handleDisconnect(); // JWT expired, have user re-authenticate
      }
    }
    return false;
  };
  // Update a user's payout preference
  const UpdatePayoutPreference = async (payoutId: string) => {
    try {
      if (!payoutId || !userToken) {
        throw new Error("No userToken or payoutId found");
      }
      await axios.post(
        APP_ENDPOINTS.UPDATE_PAYOUT_PREFERENCES,
        { payoutId },
        {
          headers: {
            authorization: `Bearer ${userToken}`,
          },
        },
      );
      await mutateUser();
      toast.info("Payout preference updated!");
    } catch (error) {
      console.log(
        error instanceof Error ? error.message : "Failed to update payout",
      );
      toast.error(
        "Failed to change payout preference. Please try again later.",
      );
    }
  };

  const UpdateEmail = async (
    newEmail: string,
    notificationsEnabled: boolean,
  ) => {
    try {
      if (!userToken) {
        throw new Error("No userToken found");
      }
      await axios.post(
        APP_ENDPOINTS.UPDATE_EMAIL,
        { email: newEmail, notificationsEnabled },
        {
          headers: {
            authorization: `Bearer ${userToken}`,
          },
        },
      );
      await mutateUser();
      return "Success";
    } catch (error) {
      console.error(error);
      if (axios.isAxiosError(error)) {
        if (error.response?.status === 409) {
          return "Email already in use";
        }
      }
      return "Failed to update email. Please try again later.";
    }
  };

  // Subscribe to/Unsubscribe from accounts when they are updated
  const UpdateAccounts = async () => {
    try {
      if (!address || !userToken) {
        throw new Error("No account found");
      }
      await axios.post(FINICITY_ENDPOINTS.SUBSCRIBE, undefined, {
        headers: {
          authorization: `Bearer ${userToken}`,
        },
      });
      await mutateUser();
      toast.info("Accounts Updated");
    } catch (error) {
      console.log(
        error instanceof Error ? error.message : "Failed to update accounts",
      );
      if (axios.isAxiosError(error) && error.response?.status === 401) {
        toast.error("You have been signed out, please sign back in.");
        await handleDisconnect(); // JWT expired, have user re-authenticate
      } else {
        toast.error("Couldn't add your accounts, please try again.");
      }
    }
  };

  // Finicity modal options and functions
  const connectEventHandlers: ConnectEventHandlers = {
    onDone: (event: ConnectDoneEvent) => {
      console.log(event);
    },
    onCancel: (event: ConnectCancelEvent) => {
      console.log(event);
    },
    onError: (event: ConnectErrorEvent) => {
      console.log(event);
    },
    onRoute: (event: ConnectRouteEvent) => {
      console.log(event);
    },
    onUser: async (event: any) => {
      if (
        [
          "AddAccountsSuccess",
          "RemoveAccountsSuccess",
          "RemoveConnectedInstitutionSuccess",
        ].includes(event?.action)
      ) {
        UpdateAccounts();
      }

      console.log(event);
    },
    onLoad: () => {
      console.log("loaded");
    },
  };
  const connectOptions: ConnectOptions = {
    popup: false,
  };
  const LaunchFinicity = async () => {
    FinicityConnect.launch(connectURL, connectEventHandlers, connectOptions);
  };

  useEffect(() => {
    FinicityConnect.destroy();
  }, []);

  // When the user is authenticated and the token is obtained, store it in a cookie
  useEffect(() => {
    if (userDataString) {
      setCookie("userToken", userDataString, { maxAge: 1 * 60 * 60 }); // 1 hour
    }
  }, [userDataString, setCookie, removeCookie]);

  //When fresh data available for account, replace/cleanup accordingly
  useEffect(() => {
    if (user && userDataString) {
      const userData = decodeToken(userDataString.userData) as UserData;
      setActivatedOffers(userData.activatedOffers);
      setMintedUptop(userData.mintedUptop);
      setHasLinkedCard(userData.hasLinkedCard);
      setHasDealPass(userData.hasDealPass);
      setDeviceID(userData.deviceID);
      setTrackingCode(userData?.trackingCode ?? "");
      setCountry(userData.country);
      setUserToken(userDataString.userData);
      setAddress(user);
      setEmail(userData.email);
      setNotificationStatus(userData.notificationStatus);
      setPayoutPreferenceId(userData.payoutPreferenceId);
      setPayoutPreferenceSymbol(userData.payoutPreferenceSymbol);
    } else {
      setUserToken("");
      setAddress("");
      setActivatedOffers([]);
      setConnectURL("");
      setDeviceID(0);
      setTrackingCode("");
      setCountry("US");
      setHasDealPass(false);
      setHasLinkedCard(false);
      setMintedUptop(false);
      setPayoutPreferenceId("");
      setPayoutPreferenceSymbol("");
    }
  }, [user, userDataString]);

  useEffect(() => {
    // When the above useEffect is called and RequestUser() populates a userToken,
    //  We can then generate the ConnectURL separately.
    async function GenerateConnectUrl() {
      try {
        const connectUrlRes = await axios.post<ConnectUrlRes>(
          FINICITY_ENDPOINTS.GET_CONNECT_URL,
          undefined,
          {
            headers: {
              authorization: `Bearer ${userToken}`,
            },
          },
        );
        setConnectURL(connectUrlRes.data.connectURL);
      } catch (error) {
        console.log(
          error instanceof Error
            ? error.message
            : "Failed to generate connect url",
        );
        if (axios.isAxiosError(error) && error.response?.status === 401) {
          toast.error("You have been signed out, please sign back in.");
          await handleDisconnect(); // JWT expired, have user re-authenticate
        }
      }
    }

    if (userToken) {
      GenerateConnectUrl();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userToken]);

  return (
    <UptopContext.Provider
      value={{
        forbiddenMessage,
        forbiddenRedirect,

        AuthenticatedFetcher,
        address,
        email,
        notificationStatus,
        connectURL,
        userToken,
        mintedUptop,
        hasDealPass,
        hasLinkedCard,
        deviceID,
        trackingCode,
        payoutPreferenceId,
        payoutPreferenceSymbol,
        country,
        earnedRewards: earnedRewards ?? [],
        activatedOffers,
        MintDealPass,
        ClaimReward,
        activateConfetti,
        ActivateOffer,

        couponOfferStatuses,
        updateCouponOfferStauses,
        ValidateCoupon,
        UpdatePayoutPreference,
        UpdateEmail,
        LaunchFinicity,
      }}
    >
      {children}
    </UptopContext.Provider>
  );
}

export function useUptop() {
  const context = useContext(UptopContext);

  if (!context) {
    throw new Error("useUptop must be used within an UptopProvider");
  }
  return context;
}
