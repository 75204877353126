export default function shortenHexAddress(
  address: string,
  length: number = 4,
): string {
  return `${address?.substring(0, length + 2)}…${address?.substring(
    address.length - length,
  )}`;
}
export function classNames(...classes: any) {
  return classes.filter(Boolean).join(" ");
}

export function formatDateTime(isoString: string) {
  const date = new Date(isoString);
  const year = date.getFullYear();
  const month = (date.getMonth() + 1).toString().padStart(2, "0");
  const day = date.getDate().toString().padStart(2, "0");
  const hours = date.getHours().toString().padStart(2, "0");
  const minutes = date.getMinutes().toString().padStart(2, "0");

  return `${month}/${day}/${year} ${hours}:${minutes}`;
}
export function sanitizeString(str: string) {
  return str.replace(/[^a-zA-Z0-9]+/g, "").toLowerCase();
}
