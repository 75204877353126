import { useId } from "react";
type UptopTableProps = {
  className?: string;
  headers: string[];
  data: any[];
  renderRow: (row: any) => JSX.Element;
};
export default function UptopTable({
  className,
  headers,
  data,
  renderRow,
}: UptopTableProps) {
  const id = useId();
  return (
    <div className="overflow-x-auto">
      <table className={`${className} min-w-full table-auto border-collapse`}>
        <thead>
          <tr className="text-left border-b-2 text-surface-600 text-lg">
            {headers.map((header, idx) => (
              <th
                key={id + "_" + idx}
                className="px-4 py-2 font-exposureItalic font-medium"
              >
                {header}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>{data.map(renderRow)}</tbody>
      </table>
    </div>
  );
}
