type ProgressBarProps = { progress: number };
export default function ProgressBar({ progress }: ProgressBarProps) {
  return (
    <div className="flex justify-center font-exposure">
      {progress}%
      <div className="mx-auto relative flex flex-col my-auto w-3/4 lg:min-w-[100px] h-5 border-2 border-black bg-uptop-tan rounded-full shadow-uiBoxShadowMisc z-5">
        <div
          className="h-full rounded-full bg-gradient-to-r from-purple-600 via-purple-700 to-purple-600 relative overflow-hidden z-1"
          style={{ width: `${progress}%` }}
        >
          <div className="shimmer" />
        </div>
      </div>
    </div>
  );
}
