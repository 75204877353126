import LockOutlined from "@mui/icons-material/LockOutlined";
import { ReactNode } from "react";

type TokenGateProps = {
  children?: ReactNode;
  className?: string;
  unlockCondition: boolean;
  customText?: string;
};
export default function TokenGate({
  children,
  className,
  unlockCondition,
  customText,
}: TokenGateProps) {
  if (unlockCondition) {
    return <>{children}</>;
  }

  return (
    <div
      className={`${className} inline-flex gap-1 w-fit px-4 py-2 bg-slate-200 rounded-full h-fit select-none`}
    >
      <LockOutlined className="h-6 w-6 text-slate-600 my-auto" />
      <span className="my-auto font-exposure text-center text-sm md:text-lg">
        {customText ? customText : "Deal Pass Required"}
      </span>
    </div>
  );
}
