import PacmanLoader from "react-spinners/PacmanLoader";

type Props = {
  className?: string;
  size?: number;
};

export default function Loading({ className, size = 40 }: Props) {
  return (
    <PacmanLoader
      color="#F1440D"
      loading={true}
      size={size}
      aria-label="Loading Spinner"
      className={`${className} mx-auto  border-none `}
    />
  );
}
