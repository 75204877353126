import { useState } from "react";
import { useUptop } from "../../contexts/UptopContext";
import { IPromo } from "../../types";
import Badge from "../ReusableUI/Badge";
import LinkCardButton from "./LinkCard";
import SimpleButton from "./SimpleButton";

type CashbackButtonProps = {
  offer: IPromo;
  offerActive: boolean;
  width?: string;
};
export default function CashbackButton({
  offer,
  offerActive,
  width = "w-fit lg:w-52",
}: CashbackButtonProps) {
  const { ActivateOffer, hasLinkedCard } = useUptop();
  const [isLoading, setIsLoading] = useState(false);

  if (!hasLinkedCard) {
    return (
      <LinkCardButton
        className={width}
        type="simple"
      />
    );
  }
  if (!offerActive) {
    return (
      <SimpleButton
        className={width}
        disabled={
          !offer?.cashbackAmount ||
          !offer.signature ||
          offerActive ||
          !offer.promoId ||
          isLoading
        }
        onClick={async (event) => {
          // Prevents the event from bubbling up the DOM tree, preventing any parent handlers from being notified of the event.
          event.stopPropagation();

          setIsLoading(true);
          await ActivateOffer(offer.signature);
          setIsLoading(false);
        }}
      >
        {isLoading ? "Activating..." : "Activate"}
      </SimpleButton>
    );
  }
  return (
    <Badge
      color="green"
      text="Active"
      width={width}
    />
  );
}
