import LaunchOutlined from "@mui/icons-material/LaunchOutlined";
import VisibilityOutlined from "@mui/icons-material/VisibilityOutlined";

import { useState } from "react";
import { useUptop } from "../../contexts/UptopContext";
import { IPromo } from "../../types";

type ValidateCouponButtonProps = {
  offer: IPromo;
};
export default function ValidateCouponButton({
  offer,
}: ValidateCouponButtonProps) {
  const {
    address,
    ValidateCoupon,
    couponOfferStatuses,
    updateCouponOfferStauses,
  } = useUptop();
  const [attemptMade, setAttemptMade] = useState(false);
  const [isDisabled, setIsDisabled] = useState(false);

  const code = couponOfferStatuses[address]?.[offer.promoId]?.couponCode;

  if (code) {
    return (
      <button
        className="btn-reverse btn-white  w-fit lg:min-w-[12rem] rounded-md  font-exposure justify-center items-center   text-black font-medium capitalize flex gap-2 "
        onClick={async (event) => {
          // Prevents the click from propagating to the table row
          event.stopPropagation();
          await navigator.clipboard.writeText(code);
          window.open(offer.merchantLink, "_blank"); // Programmatically navigate to the href
        }}
      >
        <span className="font-exposure text-sm">CODE: {code}</span>
        <LaunchOutlined className="my-auto " />
      </button>
    );
  }

  const isRetry = !isDisabled && attemptMade;

  const buttonText = isDisabled
    ? "Checking..."
    : isRetry
    ? "Not Found - Retry"
    : "Reveal Code";

  return (
    <button
      className="btn-reverse btn-white w-fit lg:min-w-[12rem] rounded-md  font-exposure justify-center items-center   text-black font-medium capitalize flex gap-2 "
      disabled={!offer.signature || !offer.promoId || isDisabled}
      onClick={async (event) => {
        // Prevents the click from propagating to the table row
        event.stopPropagation();

        setIsDisabled(true);
        setAttemptMade(true);
        const { couponCode, isHolder } = await ValidateCoupon(offer.signature);
        updateCouponOfferStauses(offer.promoId, isHolder, couponCode);
        setIsDisabled(false);
      }}
    >
      <VisibilityOutlined className="my-auto  mr-auto" />{" "}
      <span>{buttonText}</span>
    </button>
  );
}
