import { useState } from "react";
import useSWR from "swr";
import axios from "axios";
import { useUptop } from "../contexts/UptopContext";
import { APP_ENDPOINTS } from "../endpoints";
import { IPayoutPreference } from "../types";

const fetcher = async (url: string) => axios.get(url).then((res) => res.data);

export function useCashbackSettings() {
  const { payoutPreferenceId, UpdatePayoutPreference } = useUptop();
  const [selectedSetting, setSelectedSetting] = useState(payoutPreferenceId);
  const [loading, setLoading] = useState(false);

  const {
    data: cashbackSettings,
    isLoading: cashbackSettingsLoading,
    error,
  } = useSWR<IPayoutPreference[]>(APP_ENDPOINTS.PAYOUT_PREFERENCES, fetcher, {
    focusThrottleInterval: 30000,
  });

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setLoading(true);
    await UpdatePayoutPreference(selectedSetting);
    setLoading(false);
  };

  return {
    cashbackSettings,
    cashbackSettingsLoading,
    error,
    selectedSetting,
    setSelectedSetting,
    loading,
    handleSubmit,
  };
}
