import CurrencyBitcoinIcon from "@mui/icons-material/CurrencyBitcoin";
import EmojiEventsOutlined from "@mui/icons-material/EmojiEventsOutlined";
import ShoppingBasketOutlined from "@mui/icons-material/ShoppingBasketOutlined";
import SupportOutlined from "@mui/icons-material/SupportOutlined";
import WalletOutlined from "@mui/icons-material/WalletOutlined";
import FaceOutlined from "@mui/icons-material/FaceOutlined";

import { ReactNode, createContext, useContext, useState } from "react";

export type NavbarRoute = {
  id: string;
  path: string;
  pathName: string;
  icon: any;
  isMobile?: boolean;
};
const routes: NavbarRoute[] = [
  {
    id: "DailyOffers",
    path: "DailyOffers",
    pathName: "Daily Offers",
    icon: ShoppingBasketOutlined,
  },
  {
    id: "SpecialOffers",
    path: "SpecialOffers",
    pathName: "Special Offers",
    icon: CurrencyBitcoinIcon,
  },
  {
    id: "Challenges",
    path: "Challenges",
    pathName: "Challenges",
    icon: EmojiEventsOutlined,
  },
  {
    id: "MyWallet",
    path: "MyWallet",
    pathName: "My Wallet",
    icon: WalletOutlined,
  },
  {
    id: "YourAccount",
    path: "YourAccount",
    pathName: "Your Account",
    icon: FaceOutlined,
    isMobile: true, // until we decide to add desktop version we dont need to show this option in the sidebar
  },
  {
    id: "Help",
    path: "Help",
    pathName: "Help",
    icon: SupportOutlined,
  },
];

interface SidebarRoutingContextType {
  showMenu: boolean;
  toggleMenu: () => void;
  resetScrollPosOffers: () => void;
  routes: NavbarRoute[];
}
export const SidebarRoutingContext = createContext(
  {} as SidebarRoutingContextType,
);

type SidebarRoutingProviderProps = {
  children: ReactNode;
};
// Contains the logic for
export function SidebarRoutingProvider({
  children,
}: SidebarRoutingProviderProps) {
  const [showMenu, setShowMenu] = useState(false);

  const toggleMenu = () => {
    setShowMenu((prevShowMenu) => !prevShowMenu);
  };

  const resetScrollPosOffers = () => {
    sessionStorage.removeItem("scrollPosOffers");
  };

  return (
    <SidebarRoutingContext.Provider
      value={{
        toggleMenu,
        showMenu,
        resetScrollPosOffers,
        routes,
      }}
    >
      {children}
    </SidebarRoutingContext.Provider>
  );
}

export function useSidebarRouting() {
  const context = useContext(SidebarRoutingContext);

  if (!context) {
    throw new Error("useRouting must be used within a SidebarRoutingProvider");
  }

  return context;
}
