import ConnectButton from "../../../../components/Buttons/Connect";
import LinkCardButton from "../../../../components/Buttons/LinkCard";
import MintPassButton from "../../../../components/DealPass/MintPass";
import { useUptop } from "../../../../contexts/UptopContext";
import Status from "../Status";

export default function NavbarMenu() {
  const { address } = useUptop();

  return (
    <div className="w-full block flex-grow lg:flex lg:items-center lg:w-auto">
      <div className=" flex flex-col mt-4 lg:mt-0 lg:flex-row gap-4 ml-auto  ">
        {!address ? (
          <ConnectButton />
        ) : (
          <>
            <div className="flex flex-row mx-auto gap-2 ">
              <img
                className="h-14 w-14 "
                src="/LevelUp.png"
                alt="Level Up Logo"
              />
              <LinkCardButton type="logo" />
            </div>
            <MintPassButton />
            <Status />
          </>
        )}
      </div>
    </div>
  );
}
