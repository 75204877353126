import { useUptop } from "../../../contexts/UptopContext";
import { Voucher } from "../../../types/challenges";

type VoucherDetailsProps = { voucher: Voucher };
export default function VoucherDetails({ voucher }: VoucherDetailsProps) {
  const { payoutPreferenceSymbol } = useUptop();
  const hasCategories = voucher.categories.length > 0;
  return (
    <div className="flex flex-col gap-2 ">
      <div className="flex flex-col gap-1">
        <div className="text-surface-700 text-sm font-exposure">
          Description
        </div>
        {hasCategories ? (
          <div className="font-exposure">
            Make purchases in the supported categories to earn cash back in
            {payoutPreferenceSymbol}
          </div>
        ) : (
          <div className="font-exposure">
            Make purchases with supported partners to earn cash back in{" "}
            {payoutPreferenceSymbol}
          </div>
        )}
      </div>
      <div className="flex flex-col gap-1">
        <div className="text-surface-700 text-sm font-exposure">
          Credit Remaining
        </div>
        <div className=" text-lg font-exposure">${voucher.creditRemaining}</div>
      </div>
      {hasCategories ? (
        <div className="flex flex-col gap-1">
          <div className="text-surface-700 text-sm font-exposure">
            Supported Categories
          </div>
          <ul className="list-inside list-disc">
            {voucher.categories.map((category: string) => {
              return (
                <li
                  className="font-exposure"
                  key={category}
                >
                  {category}
                </li>
              );
            })}
          </ul>
        </div>
      ) : (
        <div className="flex flex-col gap-1">
          <div className="text-surface-700 text-sm font-exposure">
            Supported Partners
          </div>
          <ul className="list-inside list-disc">
            {voucher.partnerMerchants.map((merchant: string) => {
              return (
                <li
                  className="font-exposure"
                  key={merchant}
                >
                  {merchant}
                </li>
              );
            })}
          </ul>
        </div>
      )}
    </div>
  );
}
