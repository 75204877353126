import AccessTimeOutlined from "@mui/icons-material/AccessTimeOutlined";
import { useState } from "react";
import useSWR from "swr";
import { useUptop } from "../contexts/UptopContext";
import { APP_ENDPOINTS } from "../endpoints";
import { ChallengeSample, EarnedChallenge } from "../types/challenges";
import ChallengeModal from "../components/Challenges/ChallengeModal";
import CustomImage from "../components/ReusableUI/CustomImage";
import Loading from "../components/ReusableUI/Loading";
import UptopTable from "../components/UptopTable";
import TokenGate from "../components/DealPass/TokenGate";
import { useWindowSize } from "react-use";
import ProgressBar from "../components/ReusableUI/ProgressBar";
import axios from "axios";
import { calculatePromotionDates } from "../lib/challenges/calculatePromotionDates";

const fetcher = async (url: string) => axios.get(url).then((res) => res.data);

export default function Challenges() {
  const { width } = useWindowSize();
  const { userToken, hasLinkedCard, AuthenticatedFetcher } = useUptop();
  const userQualifiesForChallenges = !!userToken && hasLinkedCard;

  const [modalOpen, setModalOpen] = useState(false);
  const [activeChallenge, setActiveChallenge] = useState<EarnedChallenge>();

  // Will only fetch earned challenges if the user has a linked card
  const {
    data: challenges,
    isLoading,
    error,
  } = useSWR<EarnedChallenge[] | ChallengeSample[]>(
    userQualifiesForChallenges
      ? APP_ENDPOINTS.FETCH_AUTH_CHALLENGES
      : APP_ENDPOINTS.FETCH_SAMPLE_CHALLENGES,
    (url) =>
      userQualifiesForChallenges
        ? AuthenticatedFetcher(url, userToken)
        : fetcher(url),
    {
      focusThrottleInterval: 30000,
    },
  );

  const headers = userQualifiesForChallenges
    ? ["Reward", "Challenge", "Days Left ", "Goal", "Progress", ""]
    : ["Reward", "Challenge", "Task Required"];

  if (isLoading) {
    return (
      <div className=" flex justify-center items-center h-full">
        <Loading />
      </div>
    );
  }
  if (error || !Array.isArray(challenges)) {
    return (
      <h1 className="text-uptop-red font-exposure text-2xl flex justify-center items-center h-full">
        Error fetching challenges 😔
      </h1>
    );
  }
  return (
    <>
      <div className="h-full w-full  flex flex-col gap-4 px-8 pb-4 ">
        <div className="flex flex-col mt-4">
          <h1 className="font-exposure text-2xl lg:text-3xl capitalize">
            Limited time journeys
          </h1>
          <h1 className="font-exposureItalic text-uptop-purple text-2xl capitalize ">
            earn big rewards!
          </h1>
        </div>
        {!challenges.length ? (
          <h1 className="font-exposureItalic text-2xl mx-auto mt-8 text-uptop-redTint">
            No challenges found 😔
          </h1>
        ) : (
          <>
            {width >= 1280 ? (
              <div className="h-full flex-grow w-full ">
                <UptopTable
                  className="overflow-y-auto"
                  headers={headers}
                  data={challenges}
                  renderRow={(challenge: EarnedChallenge | ChallengeSample) => {
                    const isEarned = isEarnedChallenge(challenge);

                    if (isEarned) {
                      const { daysRemaining } = calculatePromotionDates(
                        challenge.startDate,
                        challenge.daysActive,
                      );

                      const readyToClaim = challenge.progress === 100;

                      return (
                        <tr key={challenge.rewardId}>
                          <td className="px-4 py-4 font-exposure text-lg">
                            <CustomImage
                              imageURL={challenge.coverPhoto}
                              alt={challenge.rewardName}
                              className="object-scale-down h-auto w-48 rounded-xl select-none "
                            />
                          </td>
                          <td className="px-4 py-4 font-exposure text-lg max-w-sm">
                            {challenge.description}
                          </td>
                          <td className="px-4 py-4 font-exposure text-xl">
                            <div className="mx-auto  flex flex-row gap-2">
                              {daysRemaining <= 0 ? (
                                <span className="font-exposure text-uptop-red">
                                  Expired
                                </span>
                              ) : (
                                <>
                                  <span className="font-exposure">
                                    {daysRemaining}d
                                  </span>{" "}
                                  <AccessTimeOutlined className="w-8 h-8 my-auto" />
                                </>
                              )}
                            </div>
                          </td>
                          <td className="px-4 py-4 font-exposure text-xl">
                            ${challenge.minimumSpend}
                          </td>
                          <td className="px-4 py-4 font-exposure text-xl">
                            {!readyToClaim ? (
                              <ProgressBar progress={challenge.progress} />
                            ) : (
                              <h1 className="font-exposure text-green-600  ">
                                Reward Earned
                              </h1>
                            )}
                          </td>
                          <td className="px-4 py-4 font-exposure text-lg">
                            <button
                              className="capitalize btn-reverse btn-tan rounded-full font-exposure font-thin tracking-wider text-lg px-6"
                              onClick={() => {
                                setActiveChallenge(challenge);
                                setModalOpen(true);
                              }}
                            >
                              Explore
                            </button>
                          </td>
                        </tr>
                      );
                    }

                    return (
                      <tr key={challenge.rewardName}>
                        <td className="px-4 py-4 font-exposure text-lg">
                          <CustomImage
                            imageURL={challenge.coverPhoto}
                            alt={challenge.rewardName}
                            className="object-scale-down h-auto w-52 rounded-xl select-none "
                          />
                        </td>
                        <td className="px-4 py-4 font-exposure text-lg max-w-sm">
                          {challenge.description}
                        </td>
                        <td className="px-4 py-4 font-exposure text-lg">
                          <TokenGate
                            unlockCondition={userQualifiesForChallenges}
                            customText={
                              !userToken
                                ? "Please connect your wallet"
                                : "Link your card above to get started"
                            }
                          ></TokenGate>
                        </td>
                      </tr>
                    );
                  }}
                />
              </div>
            ) : (
              <div className="max-w-[400px] w-full mx-auto h-auto flex flex-col gap-4   pb-12 pr-2">
                {challenges.map(
                  (challenge: EarnedChallenge | ChallengeSample) => {
                    const isEarned = isEarnedChallenge(challenge);

                    if (isEarned) {
                      const { daysRemaining } = calculatePromotionDates(
                        challenge.startDate,
                        challenge.daysActive,
                      );

                      const readyToClaim =
                        challenge.progress === 100 && !challenge.isClaimed;

                      return (
                        <div
                          key={challenge.rewardId}
                          className="border-2 border-black rounded-xl bg-white shadow-uiBoxShadowMisc text-center  flex flex-col gap-4 p-4"
                        >
                          <div className="ml-auto">
                            {daysRemaining <= 0 ? (
                              <span className="font-exposure text-uptop-red">
                                Expired
                              </span>
                            ) : (
                              <div>
                                <span className="font-exposure">
                                  {daysRemaining}d
                                </span>{" "}
                                <AccessTimeOutlined className="w-8 h-8 my-auto" />
                              </div>
                            )}
                          </div>
                          <div className="font-exposure text-lg">
                            <CustomImage
                              imageURL={challenge.coverPhoto}
                              alt={challenge.rewardName}
                              className="object-scale-down h-auto w-52 rounded-xl select-none mx-auto"
                            />
                          </div>
                          <div className="font-exposure text-lg max-w-sm">
                            {challenge.description}
                          </div>

                          <div className="font-exposure text-xl">
                            {!readyToClaim ? (
                              <ProgressBar progress={challenge.progress} />
                            ) : (
                              <h1 className="font-exposure text-green-600  ">
                                Reward Earned
                              </h1>
                            )}
                          </div>
                          <div className="font-exposure text-lg">
                            <button
                              className="capitalize btn-reverse btn-tan rounded-full font-exposure font-thin tracking-wider text-lg px-6"
                              onClick={() => {
                                setActiveChallenge(challenge);
                                setModalOpen(true);
                              }}
                            >
                              Explore
                            </button>
                          </div>
                        </div>
                      );
                    }

                    return (
                      <div
                        key={challenge.rewardName}
                        className="border-2 border-black rounded-xl shadow-uiBoxShadowMisc text-center"
                      >
                        <div className=" py-4 font-exposure text-lg">
                          <CustomImage
                            imageURL={challenge.coverPhoto}
                            alt={challenge.rewardName}
                            className="object-scale-down h-auto w-52 rounded-xl select-none mx-auto"
                          />
                        </div>
                        <div className="px-4 py-4 font-exposure text-lg max-w-sm">
                          {challenge.description}
                        </div>
                        <div className="px-4 py-4 font-exposure text-lg">
                          <TokenGate
                            unlockCondition={userQualifiesForChallenges}
                            customText={
                              !userToken
                                ? "Please connect your wallet"
                                : "Link your card above to get started"
                            }
                          ></TokenGate>
                        </div>
                      </div>
                    );
                  },
                )}
              </div>
            )}
          </>
        )}
      </div>
      <ChallengeModal
        isOpen={modalOpen}
        activeChallenge={activeChallenge}
        deactivateChallenge={() => {
          setModalOpen(false);
          setActiveChallenge(undefined);
        }}
      />
    </>
  );
}

export function isEarnedChallenge(
  challenge: EarnedChallenge | ChallengeSample,
): challenge is EarnedChallenge {
  return (challenge as EarnedChallenge).isActive !== undefined;
}
