import CheckOutlined from "@mui/icons-material/CheckOutlined";
import { useUptop } from "../../contexts/UptopContext";
import { useCashbackSettings } from "../../hooks/useCashbackSettings";
import Loading from "../ReusableUI/Loading";

type Props = { showTooltip?: boolean };
export default function CashbackSettingsForm({ showTooltip }: Props) {
  const { payoutPreferenceId } = useUptop();
  const {
    cashbackSettings,
    selectedSetting,
    setSelectedSetting,
    handleSubmit,
    loading,
    error,
    cashbackSettingsLoading,
  } = useCashbackSettings();

  if (error || !Array.isArray(cashbackSettings)) {
    return (
      <h1 className="text-uptop-red font-exposure text-lg">
        Error fetching cashback settings 😔
      </h1>
    );
  }

  if (cashbackSettingsLoading) {
    return <Loading size={20} />;
  }

  return (
    <form
      onSubmit={handleSubmit}
      className="flex flex-col w-full gap-4 mt-4"
    >
      {cashbackSettings.map((cashbackSetting) => {
        const isChecked =
          selectedSetting === cashbackSetting.payoutPreferenceId;

        return (
          <div
            className="flex flex-row justify-between items-center font-exposure group"
            key={cashbackSetting.payoutPreferenceId}
            onClick={() =>
              setSelectedSetting(cashbackSetting.payoutPreferenceId)
            }
          >
            <label className="font-exposure  text-sm flex  gap-2  cursor-pointer group-hover:text-surface-700">
              {cashbackSetting.title} ({cashbackSetting.symbol}){" "}
              {cashbackSetting.payoutPreferenceId === payoutPreferenceId && (
                <small className="my-auto text-uptop-red font-exposure">
                  current
                </small>
              )}
              <input
                type="radio"
                name="cashbackSetting"
                value={cashbackSetting.payoutPreferenceId}
                checked={isChecked}
                onChange={(e) => setSelectedSetting(e.target.value)}
                className="hidden"
              />
            </label>
            <div
              className={`w-6 h-6 flex justify-center items-center border-2 rounded border-uptop-purple ${
                isChecked
                  ? "bg-uptop-purple text-white"
                  : "hover:bg-surface-400"
              } `}
            >
              {isChecked && <CheckOutlined />}
            </div>
          </div>
        );
      })}
      <button
        type="submit"
        className="disabled:cursor-auto btn-reverse btn-purple rounded-lg w-32 font-exposure font-thin tracking-wide mx-auto text-sm "
        disabled={loading || selectedSetting === payoutPreferenceId}
      >
        {loading ? "Saving..." : "Save"}
      </button>
    </form>
  );
}
