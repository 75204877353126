import Navigation from "@mui/icons-material/Navigation";
import { Link, useLocation } from "react-router-dom";
import { REACT_APP_DISCORD_URL } from "../../../../config";
import { useJoyrideContext } from "../../../../contexts/JoyrideContext";
import { useSidebarRouting } from "../../../../contexts/SidebarRoutingContext";

export default function Sidebar() {
  const { setState } = useJoyrideContext();
  const { routes, resetScrollPosOffers } = useSidebarRouting();

  const location = useLocation();
  const currentPath = location.pathname.split("/")[1];
  return (
    <nav className="w-64  bg-white h-full flex flex-col  px-5 py-6  ">
      {routes.map((route) => {
        if (route.isMobile) {
          return null;
        }

        return (
          <Link
            id={route.id}
            key={"large_" + route.pathName}
            to={route.path}
            onClick={
              route.path === "DailyOffers" ? resetScrollPosOffers : undefined
            }
            className={`flex flex-row w-full gap-2 my-2 px-4 py-2 items-center justify-start rounded-full ${
              currentPath === route.path
                ? " bg-uptop-purple text-white shadow-uiBoxShadowMisc "
                : "text-black hover:bg-gray-200"
            }`}
          >
            <route.icon className="w-5 h-5" />
            <span className="font-exposure w-40">{route.pathName}</span>
          </Link>
        );
      })}
      <div className="mt-auto cursor-pointer flex flex-row w-full gap-2 my-2 px-4 py-2 items-center justify-start rounded-full  active:text-white active:bg-uptop-purple active:shadow-uiBoxShadowMisc  text-black hover:bg-gray-200 group">
        <button
          onClick={() => {
            setState({
              run: true,
              tourActive: true,
              stepIndex: 0,
            });
          }}
          className="flex flex-row w-full gap-2 font-exposure "
        >
          <Navigation className="w-6 my-auto " />
          Walkthrough
        </button>
      </div>
      <div className=" cursor-pointer flex flex-row w-full gap-2 my-2 px-4 py-2 items-center justify-start rounded-full  active:text-white active:bg-uptop-purple active:shadow-uiBoxShadowMisc  text-black hover:bg-gray-200 group">
        <a
          href={REACT_APP_DISCORD_URL}
          target="_blank"
          rel="noopener noreferrer"
          className="flex flex-row w-full gap-2 font-exposure "
        >
          <img
            src="./discord-mark-black.svg"
            className="w-6 group-active:hidden "
            alt="Discord"
          />
          <img
            src="./discord-mark-white.svg"
            className="w-6 hidden group-active:block "
            alt="Discord"
          />
          Discord
        </a>
      </div>
    </nav>
  );
}
