export const FINICITY_ENDPOINTS = {
  SUBSCRIBE: "/finicity/transactionSubscribe", // Initiates TX push
  GET_CONNECT_URL: "/finicity/getConnectURL", // Launches the modal
};
export const WILDFIRE_ENDPOINTS = {
  GET_OFFERS: "/app/getOffers", // Wildfire promos
};
export const APP_ENDPOINTS = {
  GET_PROMOS: "/app/getPromos", // Special promos

  // User Data
  FETCH_CUSTOMER: "/app/fetchCustomer",
  FETCH_HISTORY: "/app/fetchHistory",
  FETCH_ALL_EXPENSES: "/app/fetchExpenses", // Category spend

  // Settings
  PAYOUT_PREFERENCES: "/app/payoutPreferences", // All supported payout methods
  UPDATE_PAYOUT_PREFERENCES: "/app/updatePayoutPreferences", // Update payout method
  UPDATE_EMAIL: "/app/updateEmail", // Update email

  // Challenges
  FETCH_SAMPLE_CHALLENGES: "/app/fetchSampleChallenges", // Challenges without user data
  FETCH_AUTH_CHALLENGES: "/app/fetchAuthChallenges", // Challenges w/ progress
  FETCH_REWARDS: "/app/fetchRewards", // Fetch rewards earned by completing challenges
  CLAIM_REWARD: "/app/claimReward", // Claim a reward earned by completing challenges

  // Promos
  ACTIVATE_PROMO: "/app/activatePromo", // Cashback promo
  VALIDATE_COUPON: "/app/validateCoupon", // Coupon promo

  // NFT related
  MINT_DEAL_PASS: "/app/mintDealPass",
  FETCH_METADATA: "/app/fetchMetadata",
};
