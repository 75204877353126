import { useWindowSize } from "react-use";
import ConnectButton from "../components/Buttons/Connect";
import LinkCardButton from "../components/Buttons/LinkCard";
import SecondaryButton from "../components/Buttons/SecondaryButton";
import CashbackSettingsTabs from "../components/CashbackSettings/Tabs";
import NotificationSettingsForm from "../components/NotificationSettings/Form";
import { useUptop } from "../contexts/UptopContext";
import { useUser } from "../contexts/UserContext";
import Status from "./Layout/Navigation/Status";
import NoPage from "./NoPage";
import { Link } from "react-router-dom";
import CloseOutlined from "@mui/icons-material/CloseOutlined";
export default function YourAccount() {
  const { width } = useWindowSize();
  const { handleDisconnect } = useUser();
  const { address } = useUptop();
  // We don't support desktop yet w/ this page
  if (width >= 1024) {
    return <NoPage />;
  }

  return (
    <div className="h-full min-h-max w-full  flex flex-col gap-4 px-2 bg-white ">
      <h1 className="font-exposure text-3xl pl-4 mt-4">Your Account</h1>
      <div className=" flex flex-col mt-2  lg:flex-row gap-4 ml-auto  min-w-full px-3 ">
        {!address ? (
          <ConnectButton />
        ) : (
          <div className="flex flex-col gap-3">
            {/* Manage Cards button and account status  */}
            <section className="border-t-2 border-solid  border-surface-300">
              <h1 className="font-exposure text-xl capitalize my-2">Wallet</h1>
              <div className="flex  gap-2">
                <Status />
                <SecondaryButton
                  id="Logout"
                  className=" min-w-[7rem]   font-exposureItalic my-auto justify-center items-center text-2xl font-normal uppercase leading-snug tracking-wide "
                  onClick={handleDisconnect}
                >
                  Logout
                </SecondaryButton>
              </div>
            </section>
            <section className="border-t-2 border-solid  border-surface-300">
              <h1 className="font-exposure text-xl capitalize my-2">
                Notifications
              </h1>
              <NotificationSettingsForm showTooltip />
            </section>
            <section className="border-t-2 border-solid  border-surface-300">
              <h1 className="font-exposure text-xl capitalize my-2">
                Payout Currency
              </h1>
              <CashbackSettingsTabs />
            </section>
            <section className="border-t-2 border-solid  border-surface-300">
              <h1 className="font-exposure text-xl capitalize my-2">Cards</h1>
              <LinkCardButton
                type="logo"
                className=" gap-2"
              />
            </section>
          </div>
        )}
      </div>
      <Link
        to={"/"}
        className="bg-black rounded-full p-2 btn w-fit mx-auto mt-auto mb-8"
      >
        <CloseOutlined className="text-white " />
      </Link>
    </div>
  );
}
