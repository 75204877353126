type PaginationProps = {
  currentPage: number;
  totalPages: number;
  onPageChange: (page: number) => void;
};
export default function Pagination({
  currentPage,
  totalPages,
  onPageChange,
}: PaginationProps) {
  const pages = [];
  for (let i = 1; i <= totalPages; i++) {
    pages.push(
      <button
        key={i}
        onClick={() => onPageChange(i)}
        className={`mx-1 hover:text-surface-700 ${
          currentPage === i ? "text-uptop-red text-2xl font-bold" : ""
        }`}
      >
        {i}
      </button>,
    );
  }

  return (
    <div className="flex justify-center my-4 text-xl font-exposure ">
      {currentPage > 1 && (
        <button
          onClick={() => onPageChange(currentPage - 1)}
          className="mx-1 hover:text-surface-700"
        >
          Previous
        </button>
      )}
      {pages}
      {currentPage < totalPages && (
        <button
          onClick={() => onPageChange(currentPage + 1)}
          className="mx-1 hover:text-surface-700"
        >
          Next
        </button>
      )}
    </div>
  );
}
