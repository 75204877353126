import { Link } from "react-router-dom";
import { useWindowSize } from "react-use";
import NavbarMenu from "./Desktop/Menu";
import NavbarMobileButtons from "./Mobile/Buttons";

export default function Navbar() {
  const { width } = useWindowSize();
  return (
    <nav className="z-50 bg-white border-b-2 border-black sticky top-0 flex md:px-12 lg:px-20 items-center justify-between flex-wrap bg-transparent p-6 ">
      <div className="flex flex-row gap-4 flex-shrink-0 text-black mr-6 relative">
        <Link to={"/"}>
          <img
            className="h-12 w-auto"
            src="/UptopLogo.png"
            alt="Logo"
          />
        </Link>
      </div>
      {width < 1024 ? <NavbarMobileButtons /> : <NavbarMenu />}
    </nav>
  );
}
