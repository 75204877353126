import LogoutOutlined from "@mui/icons-material/LogoutOutlined";
import WalletOutlined from "@mui/icons-material/WalletOutlined";
import { useEffect, useState } from "react";
import { useUser } from "../../../contexts/UserContext";
import { magic } from "../../../lib/magic/magic";
import ShortenedAddress from "../../../components/ReusableUI/ShortenedAddress";
import { useWindowSize } from "react-use";

export default function Status() {
  const { user, handleDisconnect } = useUser();
  const [isMagicWallet, setIsMagicWallet] = useState(false);
  const { width } = useWindowSize();

  const showWallet = async () => {
    await magic.wallet.showUI();
  };

  useEffect(() => {
    async function checkForMagicWallet() {
      try {
        const walletInfo = await magic.wallet.getInfo();
        setIsMagicWallet(walletInfo.walletType === "magic");
      } catch {
        setIsMagicWallet(false);
      }
    }
    checkForMagicWallet();
  }, [user]);

  return (
    <div className="w-full lg:w-fit flex flex-row gap-4 h-12 mb-auto   text-black  px-4 border-2 border-black rounded-full">
      <span className="m-auto  ">
        <ShortenedAddress
          address={user || ""}
          length={3}
        />
      </span>

      {(width >= 1024 || isMagicWallet) && (
        <div className="border-r-2 my-3 border-surface-700" />
      )}

      {isMagicWallet && (
        <WalletOutlined
          className="w-12 hover:cursor-pointer my-auto hover:text-uptop-red text-black"
          onClick={showWallet}
        />
      )}
      {width >= 1024 && (
        <LogoutOutlined
          onClick={handleDisconnect}
          className="w-12 hover:cursor-pointer my-auto hover:text-uptop-red text-black "
        />
      )}
    </div>
  );
}
