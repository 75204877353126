import useSwr from "swr";
import { useUptop } from "../../contexts/UptopContext";
import { APP_ENDPOINTS } from "../../endpoints";
import { IReward, Voucher } from "../../types/challenges";
import Loading from "../ReusableUI/Loading";
import RewardCard from "./RewardCard";
import VoucherCard from "./VoucherCard";
import LearnMoreModal from "./LearnMoreModal/LearnMoreModal";
import { useState } from "react";
import { OwnedNft } from "../../types/alchemy";
export default function Rewards() {
  const { userToken, AuthenticatedFetcher } = useUptop();
  const [chosenReward, setChosenReward] = useState<Voucher | OwnedNft>();
  // Fetches rewards + vouchers
  const {
    data: rewards,
    isLoading,
    error,
    mutate: mutateRewards,
  } = useSwr<IReward>(
    userToken ? APP_ENDPOINTS.FETCH_REWARDS : null,
    (url) => AuthenticatedFetcher(url, userToken),
    {
      focusThrottleInterval: 30000,
    },
  );

  if (isLoading) {
    return (
      <div className="flex justify-center items-center h-full">
        <Loading />
      </div>
    );
  }
  if (error || !rewards) {
    return (
      <h1 className="text-uptop-red font-exposure text-2xl flex justify-center items-center h-full">
        Error fetching rewards 😔
      </h1>
    );
  }

  const vouchers = rewards.vouchers ?? {};
  const voucherCount = Object.keys(vouchers).length;
  const rewardNFTs = rewards.rewardNFTs ?? [];
  const rewardNFTCount = rewardNFTs.length;
  return (
    <>
      <div className=" w-full   flex flex-col mt-2 mb-8 ">
        <div className="pb-14 ">
          <h1 className="font-exposure text-black text-2xl mt-4 mb-8 ">
            Burn your rewards to earn cashback vouchers!
          </h1>

          <div className="flex flex-wrap  gap-8  ">
            {voucherCount > 0 &&
              Object.entries(vouchers).map(([nftAddress, voucher]) => {
                return (
                  <VoucherCard
                    key={nftAddress}
                    nftAddress={nftAddress}
                    voucher={voucher}
                    setChosenReward={setChosenReward}
                  />
                );
              })}
            {rewardNFTCount > 0 &&
              rewardNFTs.map((reward) => {
                return (
                  <RewardCard
                    reward={reward}
                    mutateRewards={mutateRewards}
                    key={reward.contract.address + reward.tokenId}
                    setChosenReward={setChosenReward}
                  />
                );
              })}
          </div>
        </div>
      </div>
      <LearnMoreModal
        reward={chosenReward}
        closeModal={() => {
          setChosenReward(undefined);
        }}
      />
    </>
  );
}
