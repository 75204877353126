import { useLocation, useParams } from "react-router-dom";
import { AffiliateOffer } from "../types/wildfire";
import AffiliateOfferView from "../components/DailyOffers/AffiliateOfferView";
import AffiliateOffers from "../components/DailyOffers/AffiliateOffers";
import { useState } from "react";

export default function DailyOffers() {
  const location = useLocation();

  const { Id } = useParams();

  // By having these states here, we can maintain the state of the search bar and featured offers toggle when opening an offer
  const [searchTerm, setSearchTerm] = useState("");
  const [showFeaturedOnly, setShowFeaturedOnly] = useState(true);

  if (Id) {
    const activeOffer = (location.state as { offer: AffiliateOffer })?.offer;

    if (activeOffer) {
      return <AffiliateOfferView activeOffer={activeOffer} />;
    }
  }

  return (
    <AffiliateOffers
      searchTerm={searchTerm}
      setSearchTerm={setSearchTerm}
      showFeaturedOnly={showFeaturedOnly}
      setShowFeaturedOnly={setShowFeaturedOnly}
    />
  );
}
