import { Tab } from "@headlessui/react";
import ConnectButton from "../components/Buttons/Connect";
import CashbackSettingsPopover from "../components/CashbackSettings/Popover";
import CommissionHistory from "../components/MyWallet/CommissionHistory";
import Rewards from "../components/MyWallet/Rewards";
import { useUptop } from "../contexts/UptopContext";
import { classNames } from "../lib/util";
import NotificationSettingsPopover from "../components/NotificationSettings/Popover";
import { useWindowSize } from "react-use";

export default function MyWallet() {
  const { address } = useUptop();
  const { width } = useWindowSize();
  const categories = [
    {
      id: 0,
      tabName: "Rewards",
      tabContent: <Rewards />,
    },
    {
      id: 1,
      tabName: "History",
      tabContent: <CommissionHistory />,
    },
  ];

  return (
    <div className="h-full w-full px-8 py-4 flex flex-col gap-4 relative">
      <h1 className="font-exposure text-2xl lg:text-3xl capitalize">
        What's in my wallet?
      </h1>
      {!address ? (
        <ConnectButton buttonText="Login To See Rewards" />
      ) : (
        <div className="flex flex-col  h-full relative ">
          <Tab.Group>
            <Tab.List className="flex space-x-1 rounded-xl bg-surface-200 shadow-uiBoxShadowMisc border-2 border-black p-1 max-w-[200px] lg:max-w-sm outline-none">
              {categories.map((category) => (
                <Tab
                  key={category.id}
                  className={({ selected }) =>
                    classNames(
                      "w-full rounded-lg py-2.5 text-sm md:text-lg font-exposure font-medium leading-5 ",
                      "ring-white ring-opacity-60 ring-offset-2 ring-offset-blue-400 focus:outline-none focus:ring-2",
                      selected
                        ? "bg-uptop-purple shadow text-white"
                        : " hover:bg-uptop-purple/70 hover:text-white text-surface-700",
                    )
                  }
                >
                  {category.tabName}
                </Tab>
              ))}
            </Tab.List>
            <Tab.Panels className="mt-2 w-full  h-full  ">
              {Object.values(categories).map((category) => (
                <Tab.Panel
                  className="h-full "
                  key={category.id}
                >
                  {category.tabContent}
                </Tab.Panel>
              ))}
            </Tab.Panels>
          </Tab.Group>
          {width >= 1024 && (
            <div className="absolute right-0 top-1  flex  gap-4">
              <CashbackSettingsPopover />
              <NotificationSettingsPopover />
            </div>
          )}
        </div>
      )}
    </div>
  );
}
