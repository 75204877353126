import { useState } from "react";
import { useWindowSize } from "react-use";
import { useUptop } from "../../contexts/UptopContext";
import { formatDateTime } from "../../lib/util";
import { AllCommissions } from "../../types";
import Pagination from "../Pagination";
import ShortenedAddress from "../ReusableUI/ShortenedAddress";
import UptopTable from "../UptopTable";
import Launch from "@mui/icons-material/Launch";
import Badge from "../ReusableUI/Badge";

export default function CommissionHistory() {
  const { earnedRewards } = useUptop();
  const { width } = useWindowSize();
  function calculateCashback() {
    let totalCashback = 0;
    let pendingCashback = 0;

    earnedRewards.forEach((reward) => {
      if (reward.Status === "PAID") {
        totalCashback += reward.CashbackAmount;
      } else {
        pendingCashback += reward.CashbackAmount;
      }
    });

    return {
      totalCashback: totalCashback.toFixed(2),
      pendingCashback: pendingCashback.toFixed(2),
    };
  }
  const { totalCashback, pendingCashback } = calculateCashback();

  // Pagination logic
  const [currentPage, setCurrentPage] = useState(1);
  function handlePageChange(newPage: number) {
    setCurrentPage(newPage);
  }

  const itemsPerPage = 8;
  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const totalPages = Math.ceil(earnedRewards.length / itemsPerPage);

  const displayedRewards = earnedRewards.slice(startIndex, endIndex);

  return (
    <div className="relative  h-full flex flex-col  my-8">
      {displayedRewards.length > 0 ? (
        <>
          <div className="ml-4 flex flex-row gap-20 ">
            <div className="flex flex-col font-exposure text-2xl ">
              Total Cashback
              <span className="text-3xl font-exposure ">${totalCashback}</span>
            </div>
            <div className="flex flex-col font-exposure text-2xl ">
              Pending Cashback{" "}
              <span className="text-3xl  font-exposure  ">
                ${pendingCashback}
              </span>
            </div>
          </div>

          <div className="w-fit lg:absolute top-0 right-[10%] ml-auto ">
            {totalPages > 1 && (
              <Pagination
                currentPage={currentPage}
                totalPages={totalPages}
                onPageChange={handlePageChange}
              />
            )}
          </div>

          {width >= 1024 ? (
            <div className="mt-4  h-full flex-grow">
              <UptopTable
                data-test-id="commission-history-table"
                headers={[
                  "Date",
                  "Merchant",
                  "Purchase Amount",
                  "Cashback Amount",
                  "Status",
                  "Transaction Hash",
                ]}
                data={displayedRewards}
                renderRow={(reward: AllCommissions) => {
                  const MerchantName = reward.MerchantName.split(" 0x")[0];
                  return (
                    <tr key={reward.TransactionHash || reward.CommissionDate}>
                      <td className="px-4 py-4 font-exposure text-lg">
                        {formatDateTime(reward.CommissionDate)}
                      </td>
                      <td className="px-4 py-4 font-exposure text-lg">
                        {reward.MerchantLink ? (
                          <a
                            href={reward.MerchantLink}
                            target="_blank"
                            rel="noopener noreferrer"
                            className="flex gap-1 font-exposure hover:underline"
                          >
                            <Launch className="my-auto" />
                            {MerchantName}
                          </a>
                        ) : (
                          MerchantName
                        )}
                      </td>
                      <td className="px-4 py-4 font-exposure text-lg">
                        ${reward.PurchaseAmount}
                      </td>
                      <td className="px-4 py-4 font-exposure text-lg">
                        {reward.CashbackCurrency ? (
                          `${reward.CashbackAmount.toFixed(2)} ${
                            reward.CashbackCurrency
                          }`
                        ) : (
                          <>
                            ${reward.CashbackAmount.toFixed(2)}
                            <span className="font-exposure text-uptop-redTint text-lg">
                              {" "}
                              *
                            </span>
                          </>
                        )}
                      </td>
                      <td className="px-4 py-4 font-exposure text-lg">
                        <Badge
                          color={reward.Status === "PAID" ? "purple" : "red"}
                          text={reward.Status}
                        />
                      </td>
                      <td className="px-4 py-4 font-exposure text-lg">
                        {reward.TransactionHash ? (
                          <ShortenedAddress
                            address={reward.TransactionHash}
                            length={2}
                            type="tx"
                            textColor="text-surface-700"
                          />
                        ) : (
                          "N/A"
                        )}
                      </td>
                    </tr>
                  );
                }}
              />
            </div>
          ) : (
            <div className="w-full flex flex-col gap-4 mt-2 pb-8">
              {displayedRewards.map((reward) => {
                const MerchantName = reward.MerchantName.split(" 0x")[0];
                return (
                  <div
                    key={reward.TransactionHash || reward.CommissionDate}
                    className="flex flex-col gap-2   p-4 w-full bg-white border-2 border-black shadow-uiBoxShadowMisc rounded-xl text-left"
                  >
                    <div className="flex flex-col gap-1">
                      <div className="text-surface-700 text-sm font-exposure">
                        Date
                      </div>
                      <div className="font-exposure">
                        {formatDateTime(reward.CommissionDate).split(" ")[0]}
                      </div>
                    </div>

                    <div className="flex flex-col gap-1">
                      <div className="text-surface-700 text-sm font-exposure">
                        Total Spent
                      </div>
                      <div className="font-exposure">
                        ${reward.PurchaseAmount}
                      </div>
                    </div>
                    <div className="flex flex-col gap-1">
                      <div className="text-surface-700 text-sm font-exposure">
                        Cashback
                      </div>
                      <div className="font-exposure">
                        {reward.CashbackCurrency ? (
                          `${reward.CashbackAmount.toFixed(2)} ${
                            reward.CashbackCurrency
                          }`
                        ) : (
                          <>
                            ${reward.CashbackAmount.toFixed(2)}
                            <span className="font-exposure text-uptop-redTint text-lg">
                              {" "}
                              *
                            </span>
                          </>
                        )}
                      </div>
                    </div>

                    <div className="flex flex-col gap-1">
                      <div className="text-surface-700 text-sm font-exposure">
                        Status
                      </div>
                      <Badge
                        color={reward.Status === "PAID" ? "purple" : "red"}
                        text={reward.Status}
                      />
                    </div>

                    <div className="flex flex-col gap-1">
                      <div className="text-surface-700 text-sm font-exposure">
                        Merchant
                      </div>
                      <div className="font-exposure">
                        {reward.MerchantLink ? (
                          <a
                            href={reward.MerchantLink}
                            target="_blank"
                            rel="noopener noreferrer"
                            className="flex gap-1 font-exposure hover:underline"
                          >
                            <Launch className="my-auto" />
                            {MerchantName}
                          </a>
                        ) : (
                          MerchantName
                        )}
                      </div>
                    </div>
                    <div className="flex flex-col gap-1">
                      <div className="text-surface-700 text-sm font-exposure">
                        Tx Hash
                      </div>
                      <div className="font-exposure">
                        {reward.TransactionHash ? (
                          <ShortenedAddress
                            address={reward.TransactionHash}
                            length={3}
                            type="tx"
                          />
                        ) : (
                          "N/A"
                        )}
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          )}
          <div className="font-exposure text-uptop-redTint text-lg my-4 ">
            * Will be converted to currency of choice in the Cashback Settings
            tab when the transaction is approved.
          </div>
        </>
      ) : (
        <div className="  flex flex-col gap-4">
          <h1 className="font-exposureItalic text-2xl   text-black capitalize">
            Track your progress
          </h1>
          <h1 className="mx-auto font-exposure text-lg md:text-xl  text-uptop-redTint">
            Claim cashback offers, shop with our partners, and burn rewards to
            see your payouts!
          </h1>
        </div>
      )}
    </div>
  );
}
