import AddCircle from "@mui/icons-material/AddCircle";
import CustomImage from "../ReusableUI/CustomImage";

type OfferCardAffiliateMobileProps = {
  name: string;
  title: string;
  onClick: React.MouseEventHandler<HTMLButtonElement> | undefined;
  imageUrl?: string;
};
export default function OfferCardAffiliateMobile({
  name,
  title,
  onClick,
  imageUrl,
}: OfferCardAffiliateMobileProps) {
  return (
    <button
      className="w-24 h-32  flex flex-col gap-1 border-2 border-black rounded-lg shadow-uiButtonBoxShadow overflow-hidden relative bg-white hover:bg-surface-200 group"
      onClick={onClick}
    >
      <AddCircle className="ml-auto text-black group-hover:text-uptop-purple" />
      <CustomImage
        className="w-16 h-16  object-cover mx-auto rounded-lg"
        imageURL={imageUrl}
        alt={name}
        useFallback={false}
      />
      <span className="text-xs font-exposure mx-auto capitalize py-2">
        {title}
      </span>
    </button>
  );
}
