import { Switch } from "@headlessui/react";
import axios from "axios";
import { useEffect, useMemo, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { useWindowSize } from "react-use";
import useSWR from "swr";
import { useUptop } from "../../contexts/UptopContext";
import { WILDFIRE_ENDPOINTS } from "../../endpoints";
import { sanitizeString } from "../../lib/util";
import { AffiliateOffer } from "../../types/wildfire";
import Loading from "../ReusableUI/Loading";
import OfferCardAffiliate from "./OfferCardAffiliate";
import OfferCardAffiliateMobile from "./OfferCardAffiliateMobile";

export type CategoryType = { category: string; count: number };

const fetcher = async (url: string) => axios.get(url).then((res) => res.data);

// Specify what qualifies as a featured offer here
const isFeatured = (offer: AffiliateOffer) => offer.Score > 0.1;

type AffiliateOffersProps = {
  searchTerm: string;
  setSearchTerm: React.Dispatch<React.SetStateAction<string>>;
  showFeaturedOnly: boolean;
  setShowFeaturedOnly: React.Dispatch<React.SetStateAction<boolean>>;
};
export default function AffiliateOffers({
  searchTerm,
  setSearchTerm,
  showFeaturedOnly,
  setShowFeaturedOnly,
}: AffiliateOffersProps) {
  const navigate = useNavigate();
  const { width } = useWindowSize();
  const containerRef = useRef<HTMLDivElement | null>(null);

  const { country, payoutPreferenceSymbol } = useUptop();

  const {
    data: affiliateOffers,
    isLoading: affiliateOffersLoading,
    error: affiliateOffersError,
  } = useSWR<AffiliateOffer[]>(WILDFIRE_ENDPOINTS.GET_OFFERS, fetcher, {
    focusThrottleInterval: 30000,
  });

  const sortedOffers = useMemo(() => {
    if (!affiliateOffers || !Array.isArray(affiliateOffers)) {
      return [];
    }
    return affiliateOffers
      .filter(
        (offer) =>
          (showFeaturedOnly ? isFeatured(offer) : true) &&
          offer.Countries?.includes(country),
      )
      .filter((offer) => {
        const searchTermLower = sanitizeString(searchTerm);
        const offerNameMatches = sanitizeString(offer.Name).includes(
          searchTermLower,
        );

        return offerNameMatches;
      })
      .sort((offerA, offerB) => {
        const percentageRegex = /(\d+(?:\.\d+)?)%/;
        const dollarRegex = /\$(\d+(?:\.\d+)?)/;

        const offerAPercentage = parseFloat(
          percentageRegex.exec(offerA.maxReward)?.[1] ?? "0",
        );
        const offerBDollar = parseFloat(
          dollarRegex.exec(offerB.maxReward)?.[1] ?? "0",
        );
        const offerBPercentage = parseFloat(
          percentageRegex.exec(offerB.maxReward)?.[1] ?? "0",
        );
        const offerADollar = parseFloat(
          dollarRegex.exec(offerA.maxReward)?.[1] ?? "0",
        );

        // Compare the offers first by percentage, then by dollar
        if (offerAPercentage > offerBPercentage) {
          return -1;
        } else if (offerAPercentage < offerBPercentage) {
          return 1;
        } else {
          return offerBDollar - offerADollar;
        }
      });
  }, [affiliateOffers, country, searchTerm, showFeaturedOnly]);

  useEffect(() => {
    const currentRef = containerRef.current;

    const handleScroll = () => {
      if (currentRef) {
        sessionStorage.setItem(
          "scrollPosOffers",
          currentRef.scrollTop.toString(),
        );
      }
    };

    // Add scroll event listener when the component mounts
    if (currentRef) {
      currentRef.addEventListener("scroll", handleScroll);
    }

    // Clean up by removing event listener when the component unmounts
    return () => {
      if (currentRef) {
        currentRef.removeEventListener("scroll", handleScroll);
      }
    };
  }, []);

  useEffect(() => {
    const currentRef = containerRef.current;

    if (affiliateOffers && affiliateOffers.length > 0 && currentRef) {
      const scrollPosStorage = sessionStorage.getItem("scrollPosOffers");
      if (scrollPosStorage) {
        currentRef.scrollTop = parseInt(scrollPosStorage);
      }
    }
  }, [affiliateOffers]);

  if (affiliateOffersLoading) {
    return (
      <div className=" flex justify-center items-center h-full">
        <Loading />
      </div>
    );
  }
  if (affiliateOffersError || !Array.isArray(sortedOffers)) {
    return (
      <h1 className="text-uptop-red font-exposure text-2xl text-center flex justify-center items-center h-full">
        Error fetching daily offers 😔
      </h1>
    );
  }
  return (
    <div
      ref={containerRef}
      className="h-full w-full  flex flex-col px-4 py-4 "
    >
      <div className="flex flex-col lg:flex-row  mb-4  gap-4 px-4">
        <h1 className="font-exposure text-2xl lg:text-3xl capitalize">
          Earn cashback in crypto
        </h1>
        <div className=" max-w-fit mx-auto lg:mr-0 lg:ml-auto flex flex-col lg:flex-row gap-2 lg:gap-0  px-4 ">
          <div className="min-w-fit mx-auto bg-white px-3 py-1 border-r-1 border-2 border-black  rounded-full lg:rounded-r-none flex flex-row gap-2  ">
            <Switch.Group>
              <Switch.Label className="my-auto font-exposure font-thin capitalize lg:text-xl">
                Featured
              </Switch.Label>
              <Switch
                checked={showFeaturedOnly}
                onChange={setShowFeaturedOnly}
                className={`${
                  showFeaturedOnly ? "bg-uptop-redTint" : "bg-uptop-purple"
                }
          relative  inline-flex h-[34px] lg:h-[38px] w-[74px] shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus-visible:ring-2  focus-visible:ring-white focus-visible:ring-opacity-75`}
              >
                <span className="sr-only">Use setting</span>
                <span
                  aria-hidden="true"
                  className={`${
                    !showFeaturedOnly ? "translate-x-9" : "translate-x-0"
                  }
            pointer-events-none inline-block  h-[30px] lg:h-[34px] w-[34px] transform rounded-full bg-white  ring-0 transition duration-200 ease-in-out`}
                />
              </Switch>
              <Switch.Label className="my-auto font-exposure font-thin capitalize lg:text-xl">
                All Offers
              </Switch.Label>
            </Switch.Group>
          </div>

          <input
            type="text"
            placeholder="Search"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            className=" lg:rounded-l-none rounded-full w-full  lg:border-l-0 border-2 border-black py-2 px-4  font-exposure outline-none"
          />
        </div>
      </div>
      {!sortedOffers.length ? (
        <h1 className="font-exposureItalic text-2xl mx-auto text-surface-700">
          No offers found 😔
        </h1>
      ) : (
        <div className="flex flex-wrap gap-4 lg:gap-x-6 lg:gap-y-8 justify-evenly  w-full pb-4 pr-4 overflow-y-auto">
          {sortedOffers.map((offer) => {
            const couponsAvailable = offer.coupons?.length ?? 0;
            const couponText = couponsAvailable
              ? `${couponsAvailable} ${
                  couponsAvailable === 1 ? "Coupon" : "Coupons"
                } `
              : "";
            if (width >= 1024) {
              return (
                <OfferCardAffiliate
                  key={offer.ID}
                  title={offer.Name}
                  category={couponText}
                  subtitle={`${offer.maxReward} in ${
                    payoutPreferenceSymbol || "crypto"
                  }`}
                  onClick={() => {
                    navigate("/DailyOffers/" + offer.ID, {
                      state: { offer },
                    });
                  }}
                  imageUrl={offer.Images[0]?.URL}
                />
              );
            } else {
              return (
                <OfferCardAffiliateMobile
                  key={offer.ID}
                  name={offer.Name}
                  title={offer.maxReward
                    .replace("Earn up to ", "")
                    .replace("Earn ", "")}
                  onClick={() => {
                    navigate("/DailyOffers/" + offer.ID, {
                      state: { offer },
                    });
                  }}
                  imageUrl={offer.Images[0]?.URL}
                />
              );
            }
          })}
        </div>
      )}
    </div>
  );
}
