import { KeyedMutator } from "swr";
import { useUptop } from "../../contexts/UptopContext";
import { IReward } from "../../types/challenges";
import { useState } from "react";

type ClaimRewardProps = {
  contractAddress: string;
  tokenId: string;
  mutateRewards: KeyedMutator<IReward>;
};
export default function ClaimReward({
  contractAddress,
  tokenId,
  mutateRewards,
}: ClaimRewardProps) {
  const { ClaimReward } = useUptop();
  const [isLoading, setIsLoading] = useState(false);
  return (
    <button
      disabled={isLoading}
      onClick={async () => {
        setIsLoading(true);
        const txHash = await ClaimReward(contractAddress, tokenId);
        if (txHash) {
          await mutateRewards();
        }
        setIsLoading(false);
      }}
      className="w-full btn-reverse btn-purple shadow-uiBoxShadowCard  disabled:cursor-not-allowed mt-1 my-auto text-white  px-4 md:px-8  rounded-md "
    >
      <span className="my-auto">Claim</span>
    </button>
  );
}
