import { BLOCK_EXPLORER_URLS } from "./constants";

export const {
  REACT_APP_API_SERVER = "",
  REACT_APP_RPC_URL = "",
  REACT_APP_CHAIN_ID = "",
  REACT_APP_MAGIC_KEY = "",
  REACT_APP_IS_DEV_ENV = "",
  REACT_APP_DISCORD_URL = "",
  REACT_APP_WILDFIRE_KEY = "",
} = process.env;

export const IS_PROD = process.env.NODE_ENV === "production";
export const USING_DEV_ENV = REACT_APP_IS_DEV_ENV === "true"; //development environment? (staging, deploying, etc.)

export const BLOCK_EXPLORER_URL =
  IS_PROD && !USING_DEV_ENV
    ? BLOCK_EXPLORER_URLS.polygon
    : BLOCK_EXPLORER_URLS.mumbai;
