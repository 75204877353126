import axios from "axios";
import { useWindowSize } from "react-use";
import useSWR from "swr";
import Loading from "../components/ReusableUI/Loading";
import OffersDesktop from "../components/SpecialOffers/OffersDesktop";
import OffersMobile from "../components/SpecialOffers/OffersMobile";
import { APP_ENDPOINTS } from "../endpoints";
import { IPromo } from "../types";
import TokenGateModal from "../components/SpecialOffers/TokenGateModal";
import { useState } from "react";
import CashbackOfferModal from "../components/SpecialOffers/CashbackOfferModal";
const fetcher = async (url: string) => axios.get(url).then((res) => res.data);

export default function SpecialOffers() {
  const { width } = useWindowSize();
  const [activePromo, setActivePromo] = useState<IPromo>();

  const {
    data: specialPromos,
    isLoading: specialPromosLoading,
    error: specialPromosError,
  } = useSWR<IPromo[]>(APP_ENDPOINTS.GET_PROMOS, fetcher, {
    focusThrottleInterval: 30000,
  });

  if (specialPromosLoading) {
    return (
      <div className=" flex justify-center items-center h-full">
        <Loading />
      </div>
    );
  }

  if (specialPromosError || !Array.isArray(specialPromos)) {
    return (
      <h1 className="text-uptop-red font-exposure text-2xl flex justify-center items-center h-full">
        Error fetching promotions 😔
      </h1>
    );
  }

  const sortedSpecialPromos = specialPromos.sort((a, b) => {
    return a.promoType === "cashback" ? -1 : 1;
  });

  return (
    <div className="h-full w-full px-8 py-4 flex flex-col gap-4  overflow-y-auto ">
      <h1 className="font-exposure text-3xl">Earn Rewards</h1>
      {!sortedSpecialPromos.length ? (
        <h1 className="font-exposureItalic text-2xl mx-auto mt-8 text-uptop-redTint">
          Coming soon, check back later!
        </h1>
      ) : (
        <>
          <div className="flex-grow flex flex-col pr-2 pb-3">
            {width >= 1024 ? (
              // Desktop
              <OffersDesktop
                specialPromos={sortedSpecialPromos}
                setActivePromo={setActivePromo}
              />
            ) : (
              // Mobile and Tablet
              <OffersMobile
                specialPromos={sortedSpecialPromos}
                setActivePromo={setActivePromo}
              />
            )}
          </div>
          <TokenGateModal
            activePromo={activePromo}
            setActivePromo={setActivePromo}
          />
          <CashbackOfferModal
            activePromo={activePromo}
            setActivePromo={setActivePromo}
          />
        </>
      )}
    </div>
  );
}
