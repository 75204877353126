import { Dialog, Transition } from "@headlessui/react";
import CloseOutlined from "@mui/icons-material/CloseOutlined";
import { Fragment } from "react";
import { IS_PROD } from "../../config";
import { NetworkName } from "../../constants";
import { IPromo } from "../../types";
import CustomImage from "../ReusableUI/CustomImage";
import ShortenedAddress from "../ReusableUI/ShortenedAddress";
import ViewCollection from "../ReusableUI/ViewCollection";

type Props = {
  activePromo: IPromo | undefined;
  setActivePromo: React.Dispatch<React.SetStateAction<IPromo | undefined>>;
};
export default function TokenGateModal({ activePromo, setActivePromo }: Props) {
  if (!activePromo || activePromo.promoType !== "coupon") return <></>;

  const network = activePromo?.network
    ? activePromo.network
    : IS_PROD
    ? "Polygon"
    : "Mumbai";

  return (
    <>
      <Transition
        appear
        show={!!activePromo}
        as={Fragment}
      >
        <Dialog
          as="div"
          className="relative z-50"
          onClose={() => setActivePromo(undefined)}
        >
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black/50 bg-opacity-100" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="w-full max-w-md transform overflow-hidden rounded-xl bg-white border-2 border-black shadow-uiBoxShadow p-6 text-left align-middle  transition-all">
                  <div className="flex flex-row">
                    <Dialog.Title
                      as="h2"
                      className="font-exposure text-2xl text-left my-auto"
                    >
                      NFT Required
                    </Dialog.Title>
                    <button
                      onClick={() => setActivePromo(undefined)}
                      className="btn-reverse btn-white  p-1 w-12 h-12 cursor-pointer rounded-lg ml-auto  "
                    >
                      <CloseOutlined />
                    </button>
                  </div>
                  <div className="my-4 flex flex-col gap-4 w-full mx-auto">
                    <CustomImage
                      imageURL={activePromo?.gateTokenImage}
                      alt={activePromo.gateContractAddress}
                      className="w-64 h-64 border-2 border-black rounded-lg mx-auto shadow-uiBoxShadowMisc"
                    />
                    {/* Row 1 */}
                    <div className="flex flex-row justify-evenly  w-full mx-auto gap-2">
                      {/* Collection Box */}
                      <div className="flex flex-col gap-2 text-xl  text-surface-700 ">
                        <span className="font-exposure font-medium text-lg">
                          Collection
                        </span>

                        <span className=" text-surface-800 max-w-[250px]  font-exposure ">
                          <ShortenedAddress
                            displayValue={activePromo.gateTokenName}
                            address={activePromo.gateContractAddress}
                            network={activePromo.network}
                          />
                        </span>
                      </div>
                      {/* Network Box */}
                      <div className="flex flex-col gap-2   text-xl text-surface-700  ">
                        <span className="font-exposure font-medium text-lg">
                          Network
                        </span>

                        <span className=" text-surface-800  font-exposure capitalize">
                          {network}
                        </span>
                      </div>
                    </div>
                    {/* Row 2 */}
                    <div className="flex flex-row justify-between w-full mx-auto gap-2 ">
                      {/* Token Ids Box */}
                      {activePromo?.gateTokenIds &&
                        activePromo.gateTokenIds.length > 0 && (
                          <div className="flex flex-col gap-2  text-surface-700  ">
                            <span className="font-exposure font-medium text-lg">
                              Token Ids
                            </span>
                            <span className=" text-surface-800  font-exposure capitalize w-32 md:w-48">
                              {activePromo.gateTokenIds.join(", ")}
                            </span>
                          </div>
                        )}
                    </div>
                    <ViewCollection
                      contractAddress={activePromo.gateContractAddress}
                      network={
                        activePromo.network
                          ? (activePromo.network as NetworkName)
                          : IS_PROD
                          ? "polygon"
                          : "mumbai"
                      }
                    />
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </>
  );
}
