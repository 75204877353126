import { useState } from "react";
import { useUptop } from "../../contexts/UptopContext";
import PrimaryButton from "../Buttons/PrimaryButton";
type MintPassButtonProps = {
  className?: string;
  style?: React.CSSProperties;
};
export default function MintPassButton({
  className,
  style,
}: MintPassButtonProps) {
  const { mintedUptop, MintDealPass, userToken, hasDealPass } = useUptop();
  const [isLoading, setIsLoading] = useState(false);

  if (!userToken || mintedUptop || hasDealPass) {
    return <></>;
  }

  return (
    <PrimaryButton
      className={`m-auto ${className}`}
      style={style}
      disabled={mintedUptop || isLoading}
      onClick={async () => {
        setIsLoading(true);
        await MintDealPass();
        setIsLoading(false);
      }}
    >
      {isLoading ? `Minting...` : `Mint Deal Pass`}
    </PrimaryButton>
  );
}
