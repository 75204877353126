import { ButtonHTMLAttributes, ReactNode } from "react";

type PrimaryButtonProps = {
  children?: ReactNode;
  className?: string;
} & ButtonHTMLAttributes<HTMLButtonElement>;

export default function PrimaryButton({
  children,
  className,
  ...props
}: PrimaryButtonProps) {
  return (
    <button
      className={`${className} btn-reverse btn-red flex flex-row  h-14  rounded-md w-auto group relative outline-none `}
      {...props}
    >
      <span className="whitespace-nowrap font-exposureItalic text-stroke my-auto text-md tracking-widest">
        {children}
      </span>
    </button>
  );
}
