import Navigation from "@mui/icons-material/Navigation";
import { REACT_APP_DISCORD_URL } from "../config";
import { useJoyrideContext } from "../contexts/JoyrideContext";
import { useWindowSize } from "react-use";

const Information = [
  {
    PayoutCategory: "Daily Offers",
    Instructions: [
      "After a sale is completed from one of our daily offers, the associated rewards will appear in your wallet history as pending.",
      "It can take up to 48 hours (sometimes more) for merchants to notify us of a completed sale.",
      "Cashback rewards may be marked pending if a merchant places a temporary hold on the sale.",
      "Merchants typically place a hold of 30+ days to ensure the order is not canceled or returned. This hold period may be longer depending on the merchant (e.g., the hold period for hotel bookings can extend until after your stay ends).",
      "After a merchant's hold period has cleared, your transaction will be paid out to your wallet.",
      "Your claim history will be updated every 24h.",
    ],
  },
  {
    PayoutCategory: "Spending Challenges",
    Instructions: [
      "Each transaction you make with a linked card will be automatically applied towards completing spending challenges.",
      "When you complete a spending challenge, an NFT reward will be airdropped directly to your wallet.",
      "You can trade your reward on web3 marketplaces like Opensea, or you can claim them inside Uptop Deals.",
      "When you claim an NFT reward, it is burned, and you'll see a payment voucher appear in your My Wallet page.",
      "Spend as you would normally with your linked payment method to receive crypto cashback airdropped directly to your wallet upon claiming the NFT reward.",
    ],
  },
];

export default function Help() {
  const { setState } = useJoyrideContext();
  const { width } = useWindowSize();
  return (
    <div className="h-full w-full px-8 py-4 flex flex-col ">
      <div className="flex flex-col md:flex-row gap-6">
        <h1 className="font-exposure text-3xl my-auto">How It Works</h1>
        {/* Since we can't afford to fit the discord icon in the sidebar on mobile, render it here as well */}
        <a
          href={REACT_APP_DISCORD_URL}
          target="_blank"
          rel="noopener noreferrer"
          className=" w-fit flex flex-row gap-2 my-auto cursor-pointer  group  border-2 border-black rounded-full px-4 py-2 items-center justify-start text-black hover:bg-gray-200 active:text-white active:bg-uptop-purple active:shadow-uiBoxShadowMisc  "
        >
          <img
            src={"./discord-mark-black.svg"}
            alt="Discord"
            className="group-active:hidden block h-[24px] w-[24px] "
          />
          <img
            src={"./discord-mark-white.svg"}
            alt="Discord"
            className="group-active:block hidden h-[24px] w-[24px] "
          />
          Join Discord
        </a>
        {width >= 1024 && (
          <button
            onClick={() => {
              setState({
                run: true,
                tourActive: true,
                stepIndex: 0,
              });
            }}
            className=" w-fit flex flex-row gap-2 my-auto cursor-pointer  group  border-2 border-black rounded-full px-4 py-2 items-center justify-start text-black hover:bg-gray-200 active:text-white active:bg-uptop-purple active:shadow-uiBoxShadowMisc  "
          >
            <Navigation className="w-6 my-auto " />
            Walkthrough
          </button>
        )}
      </div>

      <div
        className="mt-8 grid grid-cols-1 md:grid-cols-2 gap-8 pb-8"
        data-testid="information-wrapper"
      >
        {Information.map(({ PayoutCategory, Instructions }) => (
          <div
            key={PayoutCategory}
            className="bg-white p-6 rounded-md shadow-uiBoxShadowMisc border-black border-2 "
          >
            <h3 className="font-bold text-xl mb-4">{PayoutCategory}</h3>
            <ul className="list-disc list-inside space-y-2">
              {Instructions.map((instruction, index) => (
                <li key={index}>{instruction}</li>
              ))}
            </ul>
          </div>
        ))}
      </div>
    </div>
  );
}
