import React, { useCallback, useContext, useEffect, useState } from "react";
import { toast } from "react-toastify";
import { logout } from "../lib/magic/logout";
import { magic } from "../lib/magic/magic";
import { getWeb3 } from "../lib/magic/web3";
import { useWeb3 } from "./Web3Context";
import { useCookies } from "react-cookie";
import { IS_PROD } from "../config";

interface UserContextType {
  user: string | null;
  setUser: React.Dispatch<React.SetStateAction<string | null>>;
  signature: string | null;
  disabled: boolean;
  handleDisconnect: () => Promise<void>;
  handleConnect: () => Promise<void>;
}
export const UserContext = React.createContext<UserContextType | undefined>(
  undefined,
);

export const UserProvider = ({ children }: any) => {
  const { web3, setWeb3 } = useWeb3();

  const [cookies, setCookie, removeCookie] = useCookies(["user", "signature"]);
  const [user, setUser] = useState<string | null>(cookies.user || null);
  const [signature, setSignature] = useState<string | null>(
    cookies.signature || null,
  );

  const [disabled, setDisabled] = useState(false);

  // Update state for newly connected wallet
  const handleDisconnect = useCallback(async () => {
    await logout({ setWeb3, setUser, removeCookie });
  }, [setWeb3, setUser, removeCookie]);

  const handleConnect = useCallback(async () => {
    console.log("Logging in");
    setDisabled(true);

    try {
      if (!setWeb3) throw new Error("setWeb3 is not defined");
      const [account] = await magic.wallet.connectWithUI();
      console.log("account", account);
      const web3 = (await getWeb3()) as any;
      setWeb3(web3);

      const signedMessage = await web3.eth.personal.sign(
        `Please sign to verify ownership of this wallet.\n\nBy signing, you agree to our terms of service:\nhttps://www.uptop.xyz/terms-of-service`,
        account,
      );
      if (!signedMessage) {
        throw new Error("Message not signed");
      }
      // Set expiry date 1 hour from now
      const expiryDate = new Date();
      expiryDate.setHours(expiryDate.getHours() + 1);

      console.log("Message signed:", signedMessage);
      setSignature(signedMessage);
      setCookie("signature", signedMessage, {
        expires: expiryDate,
        secure: IS_PROD,
      });

      console.log("Logged in user:", account);
      setUser(account);
      setCookie("user", account, {
        expires: expiryDate,
        secure: IS_PROD,
      });
    } catch (error) {
      console.log(error);
      toast.error(
        "Please connect your wallet and sign the message to continue.",
      );
      await handleDisconnect();
    }
    setDisabled(false);
  }, [handleDisconnect, setCookie, setWeb3]);

  useEffect(() => {
    if (!web3?.currentProvider) {
      return;
    }

    web3.currentProvider.on("accountsChanged", handleDisconnect);
    return () => {
      if (web3.currentProvider) {
        web3.currentProvider.removeListener(
          "accountsChanged",
          handleDisconnect,
        );
      }
    };
  }, [web3, handleDisconnect]);

  const value: UserContextType = React.useMemo(
    () => ({
      user,
      setUser,
      signature,
      disabled,
      handleConnect,
      handleDisconnect,
    }),
    [user, setUser, signature, disabled, handleDisconnect, handleConnect],
  );
  return <UserContext.Provider value={value}>{children}</UserContext.Provider>;
};

export const useUser = () => {
  const context = useContext(UserContext);
  if (!context) {
    throw new Error("useUser must be used within a UserProvider");
  }
  const [cookies] = useCookies(["user", "signature"]);
  const user: string | null = cookies?.user;
  const signature: string | null = cookies?.signature;
  return {
    ...context,
    user,
    signature,
  };
};
